import * as apiConst from "../taxmation-consts";
import axios from "axios";

const EMAIL_LOGIN = apiConst.EMAIL_LOGIN;
export const emailLogin = (email: string, password: string) => {
    return axios
        .post(`${process.env.REACT_APP_URL + EMAIL_LOGIN}`,
            {
                email: email,
                password: password
            },
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const CLIENT_REGISTRATION = apiConst.CLIENT_REGISTRATION;
export const clientRegistration = (clientPayload: object) => {
    return axios
    .post(`${process.env.REACT_APP_URL + CLIENT_REGISTRATION}`,clientPayload,
        {
          //
        }
        )
    .then((response) => {
        return response.data;
    })
.catch((error) => {
    return error.response.data
}
    )
}

const GOOGLE_LOGIN = apiConst.GOOGLE_LOGIN
export const googleLogin = (tokenId: string, tenantId: string, refreshToken: string) => {
    return axios
        .post(`${process.env.REACT_APP_URL + GOOGLE_LOGIN}`,
            {
                tokenId: tokenId,
                tenantId: tenantId ? tenantId : null,
                refreshToken: refreshToken
            })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const MICROSOFT_LOGIN = apiConst.MICROSOFT_LOGIN
export const microsoftLogin = (token: string, tenantId: string) => {
    return axios
        .post(`${process.env.REACT_APP_URL + MICROSOFT_LOGIN}`,
            {
                tokenId: token,
                tenantId: tenantId ? tenantId : null,
            })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const DATEV_LOGIN = apiConst.DATEV_LOGIN
export const datevLogin = (tokenId: string, tenantId: any) => {
    const stringCode = String(tokenId);
    console.log("String(tokenId)",stringCode);
    console.log("JSON.stringify(stringCode)", JSON.stringify(stringCode));
    console.log("tokenId",tokenId)
    return axios
        .post(`${process.env.REACT_APP_URL + DATEV_LOGIN}`, {
            tokenId: tokenId,
            tenantId: tenantId,
            refreshToken: null

        }
            ,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const TEST_API = apiConst.TEST_API
export const testApi = () => {
    return axios
        .get(`${process.env.REACT_APP_URL + TEST_API}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data
        })
}

const RESET_PASS = apiConst.RESET_PASS
export const resetPassword = (email: string, password: string, token: string) => {
    return axios
        .post(`${process.env.REACT_APP_URL + RESET_PASS}`,
            {
                email: email,
                password: password,
                token: token
            })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data
        })
}




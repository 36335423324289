import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Grid } from '@mui/material';
import loadingImg from '../../assets/images/payment/loading.png';
import failImg from '../../assets/images/payment/fail.png'
import successImg from '../../assets/images/payment/success.png'
import { useNavigate } from 'react-router-dom';
import { setIsSubscribed } from '../../app/slices/userDataSlice';
import { useAppDispatch } from '../../app/hooks';

export interface StatusDialogProps {
  open: boolean;
  status: string;
  onClose: () => void;
}

export default function StatusDialog(props: StatusDialogProps) {
  const { onClose, open, status } = props;
  const dispatch = useAppDispatch()

  const handleClose = () => {
    onClose();
  };

  const navigate = useNavigate();

  // const [statusT, setStatusT] = useState('fail');

  return (
    <Dialog onClose={handleClose} open={false}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '16px' // Apply to the dialog's Paper component
        }
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',  // Darken the background
            backdropFilter: 'blur(5px)',            // Apply 5px blur effect
          }
        }
      }}
    >
      <Grid item container direction='column' rowGap={3} className='dialogContainer' paddingTop={9} paddingBottom={9} paddingRight={3} paddingLeft={3} justifyContent='center' alignItems='center'>
        {status === 'loading' ?
          <>
            <Grid item>
              <img src={loadingImg} height='140px' width='140px' alt='icon' />
            </Grid>
            <Grid item className='black desktop-header-5'> Awaiting confirmation</Grid>
            <Grid item className='neutral-3 labels font-weight-400 lineHeight18 font-weight-400 ' textAlign='center'>
              This can take up to 2 minutes. <br /> Thanks for your patience.
            </Grid>
          </>
          : status === 'fail' ?
            <>
              <Grid item>
                <img src={failImg} height='109px' width='123px' alt='icon' />
              </Grid>
              <Grid container direction='column' rowGap={0.8}>
              <Grid item className='black desktop-header-5'> Payment unsuccessful</Grid>
              <Grid item className='neutral-3 labels font-weight-400 lineHeight18 font-weight-400 ' textAlign='center'>
                Redirecting you to previous page.
              </Grid>
              </Grid>
              <Grid item width='125px' paddingTop={1}>
                <button className='blueButton' onClick={() => handleClose()} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Retry payment</button>
              </Grid>
            </>
            : status === 'success' ?
              <>
                <Grid item>
                  <img src={successImg} height='140px' width='140px' alt='icon' />
                </Grid>
                <Grid container direction='column'>
                  <Grid item className='black desktop-header-5'> Payment Successful</Grid>
                  <Grid item className='neutral-3 labels font-weight-400 lineHeight18 font-weight-400 ' textAlign='center'>
                    Redirecting you to account creation.
                  </Grid>
                </Grid>

                <Grid item width='125px' paddingTop={3}>
                  <button className='blueButton'
                    style={{ paddingTop: '20px', paddingBottom: '20px' }}
                    onClick={() => {
                      localStorage.setItem("isSubscribed", 'true');
                      dispatch(setIsSubscribed(true));
                      navigate('/users')

                    }}>Proceed</button>
                </Grid>
              </>
              :
              <></>
        }
      </Grid>
    </Dialog>
  );
}


import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import Message from '../../sharedComponents/ui/Message';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '../../assets/images/icons/homeIcon.png'
import AddTaxAdvisor from '../components/ManageUsers/Companies/AddTaxAdvisor';
import ReviewAndInvite from '../components/ManageUsers/Companies/ReviewAndInvite';
import *  as adminServices from "../../services/admin-services.proxy";
import { setLoading } from '../../app/slices/loadingSlice';
import { useAppDispatch } from '../../app/hooks';
import { setCallStatus, setErrorMsg } from '../../app/slices/apiCallSlice';
import * as userServices from '../../services/user-services.proxy';

const getUserData = userServices.getUserData;

const addTaxAdvisors = adminServices.addTaxAdvisors;

const AddTaxAdvisors = () => {

    const [activeSection, setActiveSection] = useState<string>('Add');
    const [addedTAs, setAddedTAs] = useState([""]);
    const [taxAdvisoryCount, setTaxAdvisoryCount] = useState(0);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    useEffect(() => {
        getUserData().then((x) => {
            setTaxAdvisoryCount(x.taxAdvisoryCount)

        })
    }, [])

    const addTaxAdvisorsCall = () => {
        dispatch(setLoading(true));
        addTaxAdvisors(addedTAs).then((x) => {
            if (x.ErrorMessage) {
                dispatch(setErrorMsg(x.ErrorMessage));
                dispatch(setCallStatus('Fail'));
                setTimeout(() => {
                    dispatch(setErrorMsg(''));
                    dispatch(setCallStatus(''))
                }, 4500)
            }
            else {
                dispatch(setCallStatus('Pass'));
                setTimeout(() => {
                    dispatch(setErrorMsg(''));
                    dispatch(setCallStatus(''));
                    navigate(`/admin-panel/users?type=2`);
                }, 1500)
            }
            dispatch(setLoading(false));

        })
    }

    return (
        <Grid container direction='column' rowGap={1} xs={12}>
            <Grid item container direction='column' width='100%'>

                <Grid item className='desktop-header-5'> <Message id="user.profile.add-user" className="Field-input desktop-header-5 font-weight-400" /></Grid>
                <Grid item container direction='row' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5} >
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
                            else navigate('/tasks')
                        }
                        }
                    >
                        {/* <HomeOutlinedIcon style={{ color: '#93919A' }} /> */}
                        <img src={HomeIcon} width='20px' height='20px' style={{ marginTop: '2px' }} />

                    </span>
                    <span onClick={() => {
                        if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
                        else navigate('/tasks')
                    }
                    }>    <Message id="user.profile.home" className="Field-input desktop-paragraph font-weight-400 pointerText"

                        />
                    </span>
                    / <span
                        onClick={() => {
                            if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
                            else navigate('/tasks')
                        }
                        }
                    ><Message id="user.profile.user-management" className="Field-input desktop-paragraph font-weight-400 pointerText " /> </span> / <Message id="user.profile.add-users" className="Field-input desktop-paragraph font-weight-400" />
                </Grid>
                <Grid item container direction='row' justifyContent='center' alignItems='center' columnGap={1}>
                    <Grid item container direction='column' width='120px' rowGap={1} alignItems='center'>
                        <Grid item className={activeSection === 'Add' ? 'activeCircle' : 'InactiveCircle'}>1</Grid>
                        <Grid item className={activeSection === 'Add' ? 'iris-100' : 'black'}>
                            Add Company
                        </Grid>
                    </Grid>
                    <Grid item className='blueLine'></Grid>
                    <Grid item container direction='column' width='80px' rowGap={1} alignItems='center'>
                        <Grid item className={activeSection === 'Review' ? 'activeCircle' : 'InactiveCircle'}>2</Grid>
                        <Grid item className={activeSection === 'Review' ? 'iris-100' : 'black'}> <Message id="text.review" /></Grid>
                    </Grid>
                </Grid>
                <Grid item container direction='column' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5} justifyContent='center' marginTop={1}>
                    {activeSection === "Add" ?
                        <Grid item container width='900px'>
                            <Grid item container marginTop={2}>

                                <Grid item className='activeTabs'>
                                    Add Company
                                </Grid>
                            </Grid>
                            <Grid item container bgcolor='#FEFEFF' borderRadius='0px 8px 8px 8px' boxShadow='0px 2px 4px 0px #0000001F' marginBottom={10} padding={2}>
                                <AddTaxAdvisor addedTAs={addedTAs} setAddedTAs={setAddedTAs} setActiveSection={setActiveSection} />
                            </Grid>
                        </Grid>
                        :
                        <Grid item container width='900px'>
                            <Grid item container bgcolor='#FEFEFF' borderRadius='0px 8px 8px 8px' boxShadow='0px 2px 4px 0px #0000001F' >
                                <ReviewAndInvite
                                    addTaxAdvisorsCall={addTaxAdvisorsCall} addedTAs={addedTAs} setAddedTAs={setAddedTAs} setActiveSection={setActiveSection}
                                    taxAdvisoryCount={taxAdvisoryCount}

                                />
                            </Grid>
                        </Grid>
                    }
                </Grid>

            </Grid>
        </Grid>

    );
}

export default AddTaxAdvisors
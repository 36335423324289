import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Grid, MenuItem, TextField, Backdrop, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import classes from "../../../assets/styles/Task.module.css";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import Message from '../../sharedComponents/ui/Message';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getLoadingState, setLoading } from '../../app/slices/loadingSlice';
import * as userServices from '../../services/user-services.proxy';
import { useIntl } from 'react-intl';
import { getCallStatus, getErrorMsg, setCallStatus, setErrorMsg } from '../../app/slices/apiCallSlice';


const resetToken = userServices.resetToken;



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export interface DialogProps {
    open: boolean;
    setOpen: CallableFunction;

}

const ForgotPass = (props: DialogProps) => {

    const { open, setOpen, } = props;
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState<boolean>(false);
    const [emailErrorMsg, setEmailErrorMsg] = useState<string>('');
    const intl = useIntl();


    const [display, setDisplay] = useState(1);

    const errorMsg = useAppSelector(getErrorMsg);
    const loading = useAppSelector(getLoadingState);
    const callStatus = useAppSelector(getCallStatus);


    const dispatch = useAppDispatch();


    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setEmail("")
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            handleOpen();
        }
        else {
            setOpen(false);

        }
    }, [open]);

    
    const resetTokenCall = () => {
        dispatch(setLoading(true));
        resetToken(email).then((x) => {
            if (x.ErrorMessage) {
                dispatch(setCallStatus('Fail'));
                dispatch(setErrorMsg(x.ErrorMessage))
            }
            else {
                dispatch(setCallStatus('Pass'));
            }
            setTimeout(() => {
                setDisplay(1)
                dispatch(setCallStatus(''));
                dispatch(setErrorMsg(''));
                if (!x.ErrorMessage) handleClose();
            }, 4500)
            dispatch(setLoading(false));

        })
    }


    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{ sx: { borderRadius: "8px", width: "512px", maxWidth: "none", } }}
        >

            <Grid item container padding={2.5} direction='column' rowGap={3} >
                <Grid item container className='black' fontWeight={400} alignItems='center' justifyContent='space-between'>
                <Message id="forgot.forgot-password" className='Field-input font-weight-400 font-14 ' />
                    <span style={{ cursor: 'pointer' }} onClick={() => handleClose()}><CloseIcon /></span>
                    <Grid item className='greyLine' width='100%' xs={12}></Grid>

                </Grid>
                {display === 1 ?
                    <Grid item container direction='column' xs={12}>
                        <Grid item>
                        <Message id="forgot.enter-your-email-id" className='Field-input font-weight-400 font-14 ' />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                value={email}
                                error={emailError}
                                helperText={emailError ? emailErrorMsg : ''}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    if (!e.target.value || !e.target.value.match(
                                        /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                                    )) {
                                        setEmailError(true);
                                        setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
                                    }
                                    else {
                                        setEmailError(false);
                                        setEmailErrorMsg('')
                                    }
                                }}

                                InputProps={{

                                    className: 'fieldDesign',
                                }}
                            />

                        </Grid>
                    </Grid>
                    :
                    <Grid container padding={1} textAlign='center' justifyContent='center' alignItems='center' rowGap="8px">
                        <Grid item>
                        <Message id="forgot.we-will-send-you" className='Field-input font-weight-700 mobile-paragraph' />
                        </Grid>
                        <Grid item>
                        <span className='iris-100 bold'>{email}</span> 
                        </Grid>
                        <Grid item>
                        <Message id="forgot.you-should-receive-it" className='Field-input font-weight-700 mobile-paragraph ' />
                        </Grid>
                        <Grid item>
                        <Message id="forgot.reset-password" className='Field-input font-weight-700 mobile-paragraph ' />
                        </Grid>
                     </Grid>

                }

                <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1}>
                    {errorMsg?
                    <Grid item className='red'> {errorMsg}</Grid>
                        :
                        <></>}
                    <Grid item xs={3}
                        onClick={() => {
                            if (display === 1) handleClose()
                            else setDisplay(1)



                        }}>
                        <button className='greyButton'><Message id="button.cancel" className='labels-extra-bold Field-input labelsLinks' /></button>
                    </Grid>
                    <Grid item xs={3}>
                        {/* <button
                            className='blueButton'
                            onClick={() => {
                                if (display === 1) setDisplay(2)
                            }
                            }
                            disabled={!email && display === 1}
                        >
                            Ok
                        </button> */}
                        <button
                            className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton'}
                            disabled={display === 2 && (!email || loading)}
                            onClick={() => {
                                if (display === 1) setDisplay(2)
                                    else resetTokenCall();
                            }
                            }
                        >
                            {loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                : callStatus === 'Pass' ? <CheckCircleOutlinedIcon />
                                    : callStatus === 'Fail' ? "Failed"
                                        :
                                        "Ok"
                            }</button>
                    </Grid>
                    <Grid item textAlign='center' className='errorText' paddingTop={1}>{ }</Grid>

                </Grid>



            </Grid>
        </Dialog>
    )
}

export default ForgotPass
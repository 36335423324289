import axios from 'axios';
import * as apiConst from "../taxmation-consts";

const ADD_COMMENT = apiConst.ADD_COMMENT;
export const addComment = (commentObj: any, files: File[]) => {

    const formData = new FormData();
    files.forEach((file) => {
        formData.append('files', file);
    });

    formData.append('TaskId', commentObj.taskId);
    formData.append('Content', commentObj.content);
    formData.append('Visibility', commentObj.visibility);
    formData.append('Tags', commentObj.tags);
    formData.append('Viewers', commentObj.viewers);
    formData.append('CommentId',commentObj.commentId)

    return axios
        .post(`${process.env.REACT_APP_URL + ADD_COMMENT}`, formData,
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const MOVE_DOCUMENT_TO_DATEV= apiConst.MOVE_DOCUMENT_TO_DATEV;
export const moveDocumentToDatev = (documentId: any, taskId: any) => {

    return axios
        .put(`${process.env.REACT_APP_URL + MOVE_DOCUMENT_TO_DATEV}?documentId=${documentId}&taskId=${taskId}`,
            {
                withCredentials: true,
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

import React, { useState, useEffect, useRef } from 'react';
import { TextField, Box } from '@mui/material';

interface OTPInputProps {
  numInputs: number;
  onChange: (otp: string) => void;
  value: string;
  shouldAutoFocus?: boolean;
  isNumericString?: boolean;
  isInvalid?: boolean;
  margin: string;
}

const OTPInput: React.FC<OTPInputProps> = ({
  numInputs,
  onChange,
  value,
  shouldAutoFocus = false,
  isNumericString = false,
  isInvalid = false,
  margin,
}) => {
  const [otp, setOtp] = useState<string[]>(Array(numInputs).fill(''));
  const inputRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    if (value !== otp.join('')) {
      setOtp(value.split('').concat(Array(numInputs).fill('')).slice(0, numInputs));
    }
  }, [value, numInputs]);

  useEffect(() => {
    if (shouldAutoFocus && inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [shouldAutoFocus]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const inputValue = e.target.value.slice(-1);
    if (isNumericString && isNaN(Number(inputValue)) && inputValue !== '') return;

    const newOtp = [...otp];
    newOtp[index] = inputValue;
    setOtp(newOtp);
    onChange(newOtp.join(''));

    if (inputValue && index < numInputs - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      if (!otp[index] && index > 0) {
        const newOtp = [...otp];
        newOtp[index - 1] = '';
        setOtp(newOtp);
        onChange(newOtp.join(''));
        setTimeout(() => {
          inputRefs.current[index - 1]?.focus();
        }, 0);
      }
    }
  };

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      width="100%" 
      maxWidth="360px" 
      margin={margin}
    >
      {otp.map((digit, index) => (
        <TextField
          key={index}
          id={`otp-input-${index}`}
          type={isNumericString ? 'tel' : 'text'}
          inputProps={{
            maxLength: 1,
            inputMode: isNumericString ? 'numeric' : 'text',
            pattern: isNumericString ? '[0-9]*' : '.*'
          }}
          value={digit}
          onChange={(e:any) => handleChange(e, index)}
          onKeyDown={(e:any) => handleKeyDown(e, index)}
          onFocus={handleInputFocus}
          inputRef={(el) => (inputRefs.current[index] = el as HTMLInputElement)}
          sx={{
            width: '48px',
            height: '48px',
            margin: '0 4px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: isInvalid ? 'red' : 'rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
              },
              '&.Mui-focused': {
                boxShadow: isInvalid 
                  ? '0 0 0 2px rgba(255, 0, 0, 0.3)' 
                  : '0 1px 4px 0 #5D5FEF66', 
              },
              '&:hover fieldset': {
                borderColor: isInvalid ? 'red' : 'rgba(0, 0, 0, 0.87)',
              },
              '&.Mui-focused fieldset': {
                borderColor: isInvalid ? 'red' : '#1976d2',
              },
            },
            '& .MuiInputBase-input': {
              textAlign: 'center',
              fontSize: '1.5rem',
              padding: '8px',
              color:isInvalid ? 'red' : '#030014',
              fontWeight:'bold'
            },
          }}
        />
      ))}
    </Box>
  );
};

export default OTPInput;
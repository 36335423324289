import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import filterIcon from '../../../../assets/images/icons/filterIcon.png';
import activeFilterIcon from '../../../../assets/images/icons/activeFilterIcon.png';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CheckedIcon from '../../../../assets/images/icons/CheckedIcon.png';
import UnCheckedIcon from '../../../../assets/images/icons/UncheckedIcon.png';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Grid, TextField } from '@mui/material';
import classes from "../../../assets/styles/Table.module.css";
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import selectedFilter from "../../../assets/images/icons/selectedFilter.png"
import Message from '../../../../sharedComponents/ui/Message';

export interface TableProps {
  rows: any;
  dataLength: any;
  setDataLength: any;
  paginationModel: any;
  setPaginationModel: any;
  tenants: any;
  selectedTenants: any,
  setSelectedTenants: any,
  roles: any;
  selectedRoles: any,
  setSelectedRoles: any,

}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '0px 2px 4px 0px #0000001F',
    backgroundColor: '#FEFEFF',
    borderRadius: '10px',
    padding: 0,
    // maxWidth: 'none',  // Ensure the width is not constrained
    // whiteSpace: 'nowrap',  // Prevent line breaks for content
    // overflow: 'hidden',  // Hide overflow to manage content display
    // textOverflow: 'ellipsis'
  },
}));

const Table = (props: TableProps) => {

  const { rows, tenants, selectedTenants, setSelectedTenants, roles, selectedRoles, setSelectedRoles, dataLength, setDataLength, paginationModel, setPaginationModel} = props

  const positions: string[] = [];
  const companies: string[] = [];
  const [filterIndex, setFilterIndex] = useState<number>();
  const [search, setSearch] = useState<string>();

const [filteredRoles, setFilteredRoles] = useState<any>(roles);
const [filteredTenants, setFilteredTenants] =useState<any>(tenants);

const [isOpenRoles, setIsOpenRoles]=useState(false);
const [clickedRoles, setClickedRoles]= useState<number[]>([]);
const [clickedTenants, setClickedTenants]= useState<number[]>([]);
const [isOpenCompanies, setIsOpenCompanies]= useState(false);

const handleSearch= (e:React.ChangeEvent<HTMLInputElement>)=>{
const searchValue = e.target.value.toLowerCase();
setSearch(searchValue);
const filtered = roles?.filter((role: any) => 
  role.name.toLowerCase()?.includes(searchValue)
);
setFilteredRoles(filtered);
}

const handleCompanySearch = (e:React.ChangeEvent<HTMLInputElement>)=>{
  const searchValue = e.target.value.toLowerCase();
  setSearch(searchValue);
  const filtered= tenants?.filter((tenant:any)=>
    tenant?.name?.toLowerCase()?.includes(searchValue)
  );
  setFilteredTenants(filtered);
}

useEffect(()=>{
  setFilteredRoles(roles);
},[roles])

useEffect(()=>{
setFilteredTenants(tenants);
},[tenants])
  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First name', width: 130 },
    { field: 'lastName', headerName: 'Last name', width: 130 },
    {
      field: 'roles', headerName: 'User Role', width: 170, sortable:false,
      renderHeader(params: any) {
        return (
          <ClickAwayListener  onClickAway={(event) => {
            const target = event.target as HTMLElement;
            if (
                isOpenRoles &&
                !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip') 
            ) {
                setIsOpenRoles(false);
            }
        }}>
          <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={()=> setIsOpenRoles((prev)=>!prev)}>User Role
            <HtmlTooltip
             open={isOpenRoles}
             onClose={()=>setIsOpenRoles(false)}
             disableHoverListener
             disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                    <Grid item>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder='Search'
                        onChange={handleSearch}
                      /></Grid>
                  <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={1} columnGap={2}
                    className='font-14 black normalFontWeight' padding={1}
                    >
                    {filteredRoles.map((val: any, index: number) => (
                      <Grid item container alignItems='center' key={val.id} columnGap={1} 
                      // className={classes[filterIndex === index || clickedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']} 
                      justifyContent='flex-start'
                      style={{
                        flexWrap: 'nowrap',
                        width: '100%',
                        fontSize:'14px',
                        marginBottom:'18px'}}
                        onMouseOver={(e) => {
                          const target = e.target as HTMLElement;
                          if (!clickedRoles?.includes(val.id)) {
                            target.style.cursor = 'pointer';
                            setFilterIndex(index);
                          }
                        }}
                        onMouseOut={() => {
                          setFilterIndex(-1);
                        }}
                        onClick={() => {
                          setClickedRoles(prevRoles => 
                            prevRoles.includes(val.id) 
                              ? prevRoles.filter(roleId => roleId !== val.id) 
                              : [...prevRoles, val.id] 
                          );
                        }}
                      >
                        <Grid className='bordered-box' style={{ borderColor: clickedRoles.includes(val.id) ? '#fff' : '#E6E6E8' }}
                          bgcolor={clickedRoles.includes(val.id) ? '#CCF0EB' : '#fff'}>
                            {
                              clickedRoles.includes(val.id)?
                              // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />
                              <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                              :
                            <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                            }
                            </Grid>
                        <Grid item>{val.name}</Grid>
                      </Grid>
                    ))}
                  
                  </Grid>
                  <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={1.5} style={{ cursor: clickedRoles.length > 0 ? 'pointer' : 'unset' }}
                      onClick={() => setClickedRoles([])} ><RestartAltIcon fontSize='small' />  <Message id="ta.reset-filter" className="Field-input labels font-weight-400" /></Grid>
                  <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                    <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation();
                       setClickedRoles([]);
                       setIsOpenRoles(false);
                    }}>
                    <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation();
                      setSelectedRoles(clickedRoles)
                      setIsOpenRoles(false);
                    }}>
                    <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    
                  </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              {/* <ArrowDropDownIcon /> */}
              <img src={selectedRoles.length>0 ? activeFilterIcon :filterIcon} style={{marginLeft:'5px', cursor:'pointer',borderRadius:'2px'}}/>
            </HtmlTooltip >
          </Grid >
          </ClickAwayListener>
        )
      },
    },
    { field: 'email', headerName: 'Email', width: 270 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 200 },
    // {
    //   field: 'position', headerName: 'Position', width: 150,
    //   renderHeader(params: any) {
    //     return (
    //       <Grid item container className='table-header' alignItems='center' fontWeight='normal'>Position
    //         <HtmlTooltip
    //           title={
    //             <React.Fragment>
    //               <Grid item container rowGap={1} justifyContent='center' alignItems='center' padding={1} maxHeight='250px' display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
    //                 <Grid item>
    //                   <TextField
    //                     value={search}
    //                     fullWidth
    //                     InputProps={{
    //                       startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
    //                       style: {
    //                         borderRadius: '35px',
    //                         fontFamily: 'DM Sans',
    //                         height: '37px',
    //                         backgroundColor: '#F7F9FF',
    //                         border: 0,
    //                         borderColor: '#E6E6E8',
    //                         fontSize: '12px'
    //                       },
    //                     }}
    //                     sx={{
    //                       '& .MuiInputBase-input::placeholder': {
    //                         fontWeight: 'bold',
    //                       },
    //                     }}
    //                     placeholder='Search'
    //                     onChange={(e) => setSearch(e.target.value)}
    //                   /></Grid>
    //                 {positions.map((val: string, index: number) => (
    //                   <Grid item container alignItems='center' columnGap={1} className={classes[filterIndex === index ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
    //                     onMouseOver={(e) => {
    //                       const target = e.target as HTMLElement;
    //                       target.style.cursor = 'pointer';
    //                       setFilterIndex(index);

    //                     }}
    //                     onMouseOut={() => {
    //                       setFilterIndex(-1);
    //                     }}
    //                   >
    //                     <Grid className='bordered-box' bgcolor={filterIndex === index ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index ? '#00B69B' : '#9E9E9E'} /></Grid>
    //                     <Grid item>{val}</Grid>
    //                   </Grid>
    //                 ))}
    //                 <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={1.5}
    //                 ><RestartAltIcon fontSize='small' /> Remove filter</Grid>
    //               </Grid>
    //             </React.Fragment>
    //           }
    //         >
    //           <ArrowDropDownIcon />
    //         </HtmlTooltip >
    //       </Grid >
    //     )
    //   },
    // },
    {
      field: 'companyName', headerName: 'Company Name', width: 230,sortable:false,
      renderHeader(params: any) {
        return (
          <ClickAwayListener  onClickAway={(event) => {
            const target = event.target as HTMLElement;
            if (
                isOpenCompanies &&
                !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip') 
            ) {
                setIsOpenCompanies(false);
            }
        }}>
          <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={()=> setIsOpenCompanies((prev)=>!prev)}>Company
            <HtmlTooltip
             open={isOpenCompanies}
             onClose={()=>setIsOpenCompanies(false)}
             disableHoverListener
             disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} width='307px' maxHeight='378px' padding={2} display='inline-grid'>
                    <Grid item>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder='Search'
                        onChange={handleCompanySearch}
                      /></Grid>
                      <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={1} columnGap={2} className='font-14 black normalFontWeight' padding={1}>
                    
                    {filteredTenants.map((val: any, index: number) => (
                      <Grid item container alignItems='center' key={val.id} columnGap={1}
                      //  className={classes[filterIndex === index || selectedTenants?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                        justifyContent='flex-start'
                        style={{
                          flexWrap: 'nowrap',
                          width: '100%',
                          fontSize:'14px',
                          marginBottom:'18px'}}
                        onMouseOver={(e) => {
                          const target = e.target as HTMLElement;
                          if (!clickedTenants?.includes(val.id)) {
                            target.style.cursor = 'pointer';
                            setFilterIndex(index);
                          }
                        }}
                        onMouseOut={() => {
                          setFilterIndex(-1);
                        }}
                        onClick={(e) => {
                         
                          setClickedTenants(prevTenants => 
                            prevTenants.includes(val.id) 
                              ? prevTenants.filter(tenantId => tenantId !== val.id)  
                              : [...prevTenants, val.id] 
                          );
                        }}
                      >
                        <Grid className='bordered-box' style={{ borderColor: clickedTenants.includes(val.id) ? '#fff' : '#E6E6E8' }}
                          bgcolor={clickedTenants.includes(val.id) ? '#CCF0EB' : '#fff'}>
                            {
                              clickedTenants.includes(val.id)?
                              // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />
                              <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                              :
                            <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                            }
                            </Grid>
                        <Grid item>{val.name}</Grid>
                      </Grid>
                    ))}
                    
                  </Grid>
                  <Grid item container alignItems='center' className='black font-weight-400' columnGap={2} marginLeft={1.5} style={{ cursor: clickedTenants.length > 0 ? 'pointer' : 'unset' }}
                      onClick={() => setClickedTenants([])} ><RestartAltIcon fontSize='small' />  <Message id="ta.reset-filter" className="Field-input labels font-weight-400" /></Grid>
                  <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                    <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation();
                       setClickedTenants([]);
                       setIsOpenCompanies(false);
                    }}>
                    <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation();
                      setSelectedTenants(clickedTenants)
                      setIsOpenCompanies(false);
                    }}>
                    <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    
                  </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                {/* <ArrowDropDownIcon /> {clickedTenants.length > 0 ? <img src={selectedFilter} alt='icon' /> : <></>} */}
                <img src={selectedTenants.length>0 ? activeFilterIcon :filterIcon} style={{marginLeft:'5px', cursor:'pointer',borderRadius:'2px'}}/>
              </Grid>
            </HtmlTooltip>

          </Grid >
          </ClickAwayListener>
        )
      },
    },
    { field: 'primaryContactCmpany', headerName: 'Primary Contact for Company', width: 300 },
    { field: 'country', headerName: 'Country', width: 200 },

    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 90,
    // },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
    // },
  ];

  const handlePaginationModelChange = (model: any) => {
    setPaginationModel(model);
  };

  return (
    <div style={{ height: '53vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        paginationMode='server'
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange} 
        rowCount={dataLength} 
        pageSizeOptions={[25,50,100]}
        // checkboxSelection
        disableColumnMenu
        sx={{
          border: 2,
          borderColor: '#FEFEFF',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
        }}
      />
    </div>
  )
}

export default Table
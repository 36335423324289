import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import classes from "../../assets/styles/Login.module.css";
import SSOLogin from './SSOLogin';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EmailLogin from './EmailLogin';
import SSOFail from './SSOFail';
import Message from '../../sharedComponents/ui/Message';
import VerifyUser from './VerifyUser';
// import PlanSelection from '../../pages/PlanSelection';
import mailIcon from "../../assets/images/icons/mail.png";
import googleIcon from "../../assets/images/icons/google.png";
import ChangePass from '../Profile/ChangePass';
import DatevSignUp from './DatevSignUp';
import ClientRegistration from './ClientRegistration';

const LoginContainer = ({ loginFormState, setLoginFormState }: { loginFormState: string, setLoginFormState: any }) => {

  const formState = localStorage.getItem('loginFormState');

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const [inviterName, setInviterName] = useState<any>();
  const userType = query.get("type");
  const tenantId = query.get("tenant");
  const token = query.get('token');
  const tenant = query.get('tenant');


  useEffect(() => {
    if (query.get("inviter")) {
      setInviterName(query.get("inviter")?.toUpperCase())
    }
  }, [])

  const [openChangePass, setOpenChangePass] = useState(false);

  useEffect(() => {
    if (token) {
      setOpenChangePass(true);
    }
    // else if (userType && parseInt(userType) === 1) {
    //   console.log("tettt")
    //   setLoginFormState('datev-sign-up')
        // }
    else if (userType && parseInt(userType) === 2) {
        console.log("tettt")
        setLoginFormState('client-registration')
    }
    else setLoginFormState('default')

    if(tenant) {
      localStorage.setItem('tenantId',tenant)
    }
  })

  return (
    <Grid container direction="column" className={classes['loginContainer']} width='460px' alignItems='center'>
      <Grid item container width='370px'>
        <Grid item className={loginFormState === 'SSO-fail' ? 'neutral-6 desktop-header-5' : 'neutral-6 labels'}>
          {loginFormState === 'SSO-fail' ?
            <Message
              id="error.sso-login-unsucessful"
              className='neutral-6 desktop-header-5'
            />
            :
            loginFormState === 'verify-user' ?
              <Message
                id="login.enter-your-code"
                className='neutral-6 desktop-header-5 '
              />
              : loginFormState === 'plan-select' ?
                <Message
                  id="login.thank-you-for-choosing-taxmation"
                  className='neutral-6 labels'
                />
                : inviterName ?
                  <Grid item container direction='row' columnGap={1} alignItems='center'>
                    <Message
                      id="login.youve-been-invited-by"
                      className='neutral-6 labels'
                    />
                    <span className='iris-100 labels-extra-bold'>{inviterName}</span>
                  </Grid>

                  : userType && parseInt(userType) === 1 ?
                    <Message
                      id="signup.tax-advisor-sign-up"
                      className='neutral-6 labels'
                    />
                    :

                    <Message
                      id="login.welcome-back"
                      className='neutral-6 labels'
                    />
          }
        </Grid>
        <Grid item className={loginFormState === 'SSO-fail' ? 'neutral-6 labels' : 'neutral-6 desktop-header-5'} width='370px'>
          {loginFormState === 'SSO-fail' ?
            <Message
              id="error.something-went-wrong"
              className='neutral-6 labels'
            />
            :
            loginFormState === 'verify-user' ?
              <Message
                id="login.we-sent-a-verification-code-to"
                className='neutral-6 labels'
              />
              : loginFormState === 'plan-select' ?
                <Message
                  id="login.welcome-to-tax-made-easy"
                  className='neutral-6 desktop-header-5'
                />
                : 
                // inviterName ?
                //   <Message
                //     id="login.link-your-account-with-sso"
                //     className='neutral-6 desktop-header-5'
                //   />
                  userType && parseInt(userType) === 1 ?
                    <Message
                      id="signup.sign-up-using-datev"
                      className='neutral-6 desktop-header-5'
                    />
                    :
                    userType && parseInt(userType) === 2 ?
                    <Message
                      id="login.personalize-your-account"
                      className='neutral-6 desktop-header-5'
                    />
                    :
                    <Message
                      id="login.sign-in-to-your-account"
                      className='neutral-6 desktop-header-5'
                    />
          }
        </Grid>
        {loginFormState === 'default' ?
          <Grid container marginTop={3}>
            <SSOLogin loginFormState={loginFormState} setLoginFormState={setLoginFormState} />
            {userType && parseInt(userType) === 1 ?
              <></>
              :
              <Grid item container justifyContent='center' style={{ border: '1px solid #E6E6E8', borderTop: 'none', paddingBottom: '20px', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', minWidth: '380px' }}>
                <Grid item container direction="row" paddingTop={2} paddingBottom={2} justifyContent='center' alignItems='center'>
                  <Grid item xs={5} className={classes['greyLine']}></Grid>
                  <span className='neutral-6' style={{ width: '50px', textAlign: 'center' }}>
                    <Message
                      id="text.or"
                      className='neutral-6'
                    />
                  </span>
                  <Grid item xs={5} className={classes['greyLine']}></Grid>
                </Grid>
                <Grid item>
                  {/* <button className={classes['loginButton']} onClick={() => {
              setLoginloginFormState('email-login')
              localStorage.setItem('loginloginFormState', 'email-login')
            }} disabled={userType && parseInt(userType) === 1 ? true : false}>
              <Grid item container textAlign='start' alignItems='center'
              >
                <img src={mailIcon} alt='icon' style={{ paddingRight: '77px', paddingLeft: '21px' }} width='18px' height='16px' />
                <Message
                  id="login.sign-in-with-email"
                />
              </Grid>
            </button> */}
                  <EmailLogin loginFormState={loginFormState} setLoginFormState={setLoginFormState} />
                </Grid>
              </Grid>}

          </Grid>
          : 
          loginFormState === 'email-login' ?
            <EmailLogin loginFormState={loginFormState} setLoginFormState={setLoginFormState} />
         
            : loginFormState === 'SSO-fail' ?
              <SSOFail setLoginFormState={setLoginFormState} />
              : loginFormState === 'verify-user' ?
                <VerifyUser />
                // : loginFormState === 'plan-select' ?
                //   <PlanSelection />
                //: loginFormState === 'datev-sign-up' ?
                //  <DatevSignUp />
                  :
                  loginFormState === 'client-registration' ?
                  <ClientRegistration loginFormState={loginFormState} setLoginFormState={setLoginFormState} />
                  :
                  <></>
        }
      </Grid>
      <ChangePass
        open={openChangePass}
        setOpen={setOpenChangePass}
      />

    </Grid>
  )
}

export default LoginContainer

//auth 
export const EMAIL_LOGIN = "/api/Auth";
export const GOOGLE_LOGIN = "/api/Auth/google";
export const MICROSOFT_LOGIN = "/api/Auth/microsoft";
export const DATEV_LOGIN = "/api/Auth/datev";
export const RESET_PASS = "/api/Auth/password-reset";
export const CLIENT_REGISTRATION='/api/Auth/client-registration';

//user
export const GET_USER_PROFILE = "/api/User/profile";
export const UPDATE_USER_PROFILE = "/api/User";
export const GET_TM_USER_LIST = '/api/User/list';
export const UPDATE_TM_ROLE = '/api/User/role';
export const DELETE_USER = '/api/User';
export const GET_USER_DATA = '/api/User/data';
export const USER_MODULE = '/api/User/module';
export const CHANGE_PASSWORD = '/api/User/password';
export const RESET_TOKEN = '/api/User/reset-token';

//client
export const GET_INVITED_CLIENTS = "/api/Client/clients";
export const DELETE_CLIENT = "/api/Client";
export const ADD_TEAM_MEMBERS = "/api/Client/team-members";
export const GET_CLIENT_DATA = "/api/Client/data";
export const UPDATE_CLIENT_MODULE = "/api/Client/module";

//task
export const GET_TASK_LIST = "/api/Task/list";
export const GET_TASK = "/api/Task";
export const ADD_TASK = "/api/Task";
export const UPDATE_TASK = "/api/Task";
export const DELETE_TASK = "/api/Task";
export const GET_TASK_INPUT_DATA = "/api/Task/data";
export const UPLOAD_TASK__DOCUMENT = "/api/Task/upload";
export const DELETE_TASK_DOCUMENT = "/api/Task/document";
export const DUPLICATE_TASK = "/api/Task/duplicate";
export const UPDATE_TASK_STATUS = "/api/Task/status";
export const GET_TASK_LIST_DATA = "/api/Task/list-data";
export const MOVE_TO_DATEV = "/api/Task/datev-document";

//admin
export const GET_COMPANIES_LIST = '/api/Admin/companies';
export const GET_USERS_LIST = '/api/Admin/users';
export const SWITCH_COMPANY_STATUS = '/api/Admin/company-status';
export const ADD_TAX_ADVISOR = '/api/Admin/tax-advisory';

//test
export const TEST_API = '/api/Auth/client';

//payment 
export const CHECKOUT_SESSION = '/api/Stripe/checkout-session';

//tax advisor
export const INVITE_CLIENT = '/api/TaxAdvisory/client';
export const ADD_TM = '/api/TaxAdvisory/team-memeber';

//otp
export const GENERATE_QR = '/api/OTP/generate-otp';
export const VERIFY_OTP = '/api/OTP/verify-otp';
export const VALIDATE_OTP = '/api/OTP/validate-otp';
export const DISABLE_OTP = '/api/OTP/disable-otp';

//datev
export const DATEV_AUTH_CODE_URL = '/api/Datev/auth-code-url';

//roles
export const GET_USER_ROLES = '/api/Role';

//comment
export const ADD_COMMENT = '/api/Comment';
export const MOVE_DOCUMENT_TO_DATEV = '/api/Comment/document';

//reminders
export const POST_REMINDER = '/api/Reminder';
export const IN_ACTIVE_REMINDER = '/api/Reminder/inactive';

//notifications
export const USER_NOTIFICATIONS = "/api/Notification/user-notifcation";


//user statuses
export const ACTIVE_STATUS_ID = 1;
export const IN_ACTIVE_STATUS_ID = 2;
export const INVITED_STATUS_ID= 3;

//Task statuses
export const NEW_STATUS_ID = 1;
export const OPEN_STATUS_ID = 2;
export const OVERDUE_STATUS_ID = 3;
export const IN_PROGRESS_STATUS_ID = 4;
export const CLOSED_STATUS_ID = 5;
export const WAITING_FOR_OTHERS_STATUS_ID = 6;
export const UPCOMING_STATUS_ID=7;

export const NEW_STATUS_NAME = 'New';
export const OPEN_STATUS_NAME = 'Open';
export const OVERDUE_STATUS_NAME = 'Overdue';
export const IN_PROGRESS_STATUS_NAME = 'InProgress';
export const CLOSED_STATUS_NAME = 'Closed';
export const WAITING_FOR_OTHERS_STATUS_NAME = 'WaitingForOthers';
export const UPCOMING_STATUS_NAME='Upcomming';


import React from 'react';
import { Grid } from '@mui/material';
import TASignUp from "../../assets/images/TASignup.png";
import classes from "../../assets/styles/Login.module.css";
import datevIcon from "../../assets/images/icons/datev.png";
import Message from '../../sharedComponents/ui/Message';
import { useNavigate } from 'react-router-dom';


const DatevSignUp = ({ handleDatevLogin }: { handleDatevLogin: any }) => {
    const naviagte = useNavigate();
    return (
        <Grid container direction='column' justifyContent='center' alignItems='center'>
            <Grid item marginTop='30px'>
                <img src={TASignUp} alt='ta' width='287px' height='300px' />
            </Grid>
            <Grid item marginTop='18px'>
                <button className={classes['loginButton']}
                 onClick={() => handleDatevLogin()} 
                >
                    <Grid item container textAlign='start' alignItems='center'
                    >
                        <img src={datevIcon} alt='icon' style={{ paddingRight: '77px', paddingLeft: '21px' }} width='18px' height='18px' />
                        <Message
                            id="login.sign-up-with-datev"
                        />
                    </Grid>
                </button>
            </Grid>

            <Grid container direction="row" justifyContent="center" marginTop='9vh'>
                <Message
                    id="existing-user"
                    className='black labels'

                />
                &nbsp;
                <Grid item
                    onClick={() => naviagte('/')}
                >
                    <Message
                        id="login-here"
                        className='black labels underline-text bold pointerText'
                    />
                </Grid>

            </Grid>
        </Grid>
    )
}

export default DatevSignUp

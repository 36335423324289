import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
interface UserDataState {
  token: string,
  isLoggedIn: boolean,
  isCMS: boolean,
  isSubscribed: boolean,
  isSSO: boolean,
  isOtpEnabled: boolean,
  isOtpVerified: boolean,
  userType: number,
  roles: number[],
  countries: string[];
}

const initialState: UserDataState = {
  token: '',
  isLoggedIn: false,
  isCMS: false,
  isSubscribed: false,
  isSSO: false,
  isOtpEnabled: false,
  isOtpVerified: false,
  userType: -1,
  roles: [],
  countries: []
}

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    updateToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    setUserType: (state, action: PayloadAction<number>) => {
      state.userType = action.payload
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload
    },
    setIsCMS: (state, action: PayloadAction<boolean>) => {
      state.isCMS = action.payload
    },
    setIsSubscribed: (state, action: PayloadAction<boolean>) => {
      state.isSubscribed = action.payload
    },
    setIsSSO: (state, action: PayloadAction<boolean>) => {
      state.isSSO = action.payload
    },
    setIsOtpEnabled: (state, action: PayloadAction<boolean>) => {
      state.isOtpEnabled = action.payload
    },
    setIsOtpVerified: (state, action: PayloadAction<boolean>) => {
      state.isOtpVerified = action.payload
    },
    setRoles: (state, action: PayloadAction<number[]>) => {
      state.roles = action.payload
    },
    setCountries: (state, action: PayloadAction<string[]>) => {
      state.countries = action.payload
    },
  },
})

export const { updateToken, setIsLoggedIn, setIsCMS, setIsSubscribed, setIsSSO, setIsOtpEnabled, setIsOtpVerified, setUserType, setRoles, setCountries } = userDataSlice.actions

export const userActions = userDataSlice.actions

export const getToken = (state: RootState) => state.userData.token

export const selectIsCMS = (state: RootState) => state.userData.isCMS;

export const selectIsLoggedIn = (state: RootState) => state.userData.isLoggedIn;

export const selectIsSubscribed = (state: RootState) => state.userData.isSubscribed;

export const selectIsSSO = (state: RootState) => state.userData.isSSO;

export const selectIsOtpEnabled = (state: RootState) => state.userData.isOtpEnabled;

export const selectIsOtpVerified = (state: RootState) => state.userData.isOtpVerified;

export const selectUserType = (state: RootState) => state.userData.userType;

export const selectRoles = (state: RootState) => state.userData.roles;

export const selectCountries = (state: RootState) => state.userData.countries;


export default userDataSlice.reducer
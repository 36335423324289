import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress, Grid, TextField } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeIcon from "../../assets/images/icons/homeIcon.png";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import classes from '../assets/styles/Users.module.css';
import * as adminServices from "../../services/admin-services.proxy";
import Backdrop from '@mui/material/Backdrop';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setLoading, getLoadingState } from '../../app/slices/loadingSlice';
import CompaniesTable from '../components/ManageUsers/Companies/Table';
import UsersTable from '../components/ManageUsers/Users/Table';
import { getModifyValue, setModify } from '../../app/slices/modifySlice';
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from 'react-router-dom';

const getCompaniesList = adminServices.getCompaniesList;
const getUsersList = adminServices.getUsersList;
const switchCompanyStatus = adminServices.switchCompanyStatus;

export default function ManageUsers() {

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const loading = useAppSelector(getLoadingState);
  const modify = useAppSelector(getModifyValue);

  //query params
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const queryActiveTab = query.get('type');

  const [activeTab, setActiveTab] = useState<string>(queryActiveTab && parseInt(queryActiveTab) === 2 ? 'Companies' : 'Users');
  const [companiesTable, setCompaniesTable] = useState<any>([]);
  const [usersTable, setUsersTable] = useState([]);
  const [search, setSearch] = useState<string>();
  const [tenants, setTenants] = useState([]);
  const [roles, setRoles] = useState([]);

  const intl = useIntl();

  //filters
  const [statusId, setStatusId] = useState<any>();
  const [typeId, setTypeId] = useState<any>();
  //const [tenantId, setTenantId] = useState<any>();
  const [selectedTenants, setSelectedTenants] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [dataLength, setDataLength] = useState(0);

  // const [usersPaginationModel, setUsersPaginationModel] = useState({ page: 0, pageSize: 5 });
  // const [companiesPaginationModel, setCompaniesPaginationModel] = useState({ page: 0, pageSize: 5 });

  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });




  const handleTabChange = (newTab: string) => {
    if (newTab !== activeTab) {
      setSearch('');
      setStatusId(null);
      setTypeId(null);
      setSelectedRoles([]);
      setSelectedTenants([]);
      setDataLength(0);

      if (newTab === 'Users') {
        setPaginationModel({ page: 0, pageSize: 5 });
        setUsersTable([]);
      } else {
        setPaginationModel({ page: 0, pageSize: 5 });
        setCompaniesTable([]);
      }
      setActiveTab(newTab);
    }
  };

  // const getCurrentPaginationModel = () => {
  //   return activeTab === 'Users' ? usersPaginationModel : companiesPaginationModel;
  // };

  const listData =
  {
    searchTerm: search ? search : null,
    pageNumber: 1,
    pageSize: 110,
    statusId: activeTab === 'Companies' ? statusId : null,
    typeId: activeTab === 'Companies' ? typeId : null,
    tenants: activeTab === 'Users' ? selectedTenants : null,
    roles: activeTab === 'Users' ? selectedRoles : null
  }

  const getCompaniesListCall = () => {
    dispatch(setLoading(true));
    getCompaniesList(listData).then((x) => {
      setCompaniesTable(x.companies);
      setDataLength(x.total || 0);
      dispatch(setLoading(false));
    })
  }

  const getUsersListCall = () => {
    dispatch(setLoading(true));
    getUsersList(listData).then((x) => {
      setUsersTable(x.users);
      setDataLength(x.total || 0);
      setTenants(x.tenants);
      setRoles(x.roles);
      dispatch(setLoading(false));
    })
  }

  const switchCompanyStatusCall = (tenantId: string) => {
    dispatch(setLoading(true));
    switchCompanyStatus(tenantId).then((x) => {
      if (x.ErrorMessage) {
      }
      else {
        dispatch(setModify(!modify))
      }
      dispatch(setLoading(false));
    })
  }

  useEffect(() => {
    if (activeTab === 'Users') getUsersListCall();
    else getCompaniesListCall();
  }, [modify, activeTab, search, statusId, typeId, selectedTenants, selectedRoles, paginationModel])



  return (
    <Grid container direction='column' rowGap={1} width='100%'>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item className='desktop-header-5'>Admin Manage Users</Grid>
      <Grid item container direction='row' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5} >
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
            else navigate('/tasks')
          }
          }
        >
          {/* <HomeOutlinedIcon style={{ color: '#93919A' }} /> */}
          <img src={HomeIcon} width='20px' height='20px' style={{ marginTop: '5px' }} />

        </span> Home / Admin / User-Management
        <Grid item container marginTop={2}>
          <Grid item className={activeTab === 'Users' ? 'activeTabs' : 'tabs'}
            onClick={() => handleTabChange('Users')}>
            Users
          </Grid>
          <Grid item className={activeTab === 'Companies' ? 'activeTabs' : 'tabs'}
            onClick={() => handleTabChange('Companies')}>
            Companies
          </Grid>
        </Grid>
        <Grid item container bgcolor='#FEFEFF' borderRadius='0px 8px 8px 8px' boxShadow='0px 2px 4px 0px #0000001F'>
          <Grid item container direction='row' columnGap={2} padding={1}>
            <Grid item xs={5}>
              <TextField
                value={search}
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                  style: {
                    borderRadius: '35px',
                    fontFamily: 'DM Sans',
                    height: '48px',
                    backgroundColor: '#F7F9FF',
                    border: '1px solid #E6E6E8'
                  },
                }}
                sx={{
                  '& .MuiInputBase-input::placeholder': {
                    fontWeight: 'bold',
                  },
                }}
                placeholder={intl.formatMessage({ id: 'text.search' })}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>

            {/* {activeTab === 'Companies' ?
              <>
                <Grid item className={classes['client-button']}
                  style={{ opacity: typeId === 2 ? 0.5 : 1, cursor: typeId === 2 ? 'unset' : 'pointer' }}
                  onClick={() => setTypeId(2)}
                >Client</Grid>
                <Grid item className={classes['tax-advisor-button']}
                  style={{ opacity: typeId === 1 ? 0.5 : 1, cursor: typeId === 1 ? 'unset' : 'pointer' }}
                  onClick={() => setTypeId(1)}
                >Tax Advisor</Grid>
              </>
              :
              <></>
            } */}
            <Grid item style={{ border: '1px solid #E6E6E8', borderRadius: '8px', display: 'flex', alignItems: 'center', padding: 10, cursor: 'pointer' }}
              onClick={() => {
                dispatch(setModify(!modify))
                setSearch('');
                setStatusId(null);
                setTypeId(null);
                setSelectedRoles([]);
                setSelectedTenants([]);
              }
              }><RestartAltIcon htmlColor='#C3C2C7' /></Grid>
            {activeTab === 'Companies' ?
              <Grid item width='200px'>
                <button className='blueButton'
                style={{height:'48px'}}
                  onClick={() => navigate('/admin-panel/add-ta')}>Add Tax Advisor</button>
              </Grid>
              :
              <></>
            }

          </Grid>
          {activeTab === 'Users' ?
            <UsersTable
              rows={usersTable}
              dataLength={dataLength}
              setDataLength={setDataLength}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              tenants={tenants}
              selectedTenants={selectedTenants}
              setSelectedTenants={setSelectedTenants}
              roles={roles}
              selectedRoles={selectedRoles}
              setSelectedRoles={setSelectedRoles}
            />
            :
            <CompaniesTable
              rows={companiesTable}
              dataLength={dataLength}
              setDataLength={setDataLength}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              switchCompanyStatusCall={switchCompanyStatusCall}
              loading={loading}
              statusId={statusId}
              setStatusId={setStatusId}
              typeId={typeId}
              setTypeId={setTypeId}
            />}
        </Grid>
      </Grid>
    </Grid>
  );
}

import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import deleteIcon from './../../../../assets/images/icons/delete.png';
import Message from '../../../../sharedComponents/ui/Message';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useIntl } from 'react-intl';


export interface componentProps {
    addedTAs: any;
    setAddedTAs: any;
    setActiveSection: any;

}

const AddTaxAdvisor = (props: componentProps) => {

    const { addedTAs, setAddedTAs, setActiveSection } = props;

    const [, updateState] = React.useState({});

    const forceUpdate = React.useCallback(() => {
        // Updating state with an empty object to trigger a re-render
        updateState({});
    }, []);

    const navigate = useNavigate();
    const intl = useIntl();

    const [emailError, setEmailError] = useState<boolean>(false);
    const [emailErrorMsg, setEmailErrorMsg] = useState<string>('');
    const [lineIndex, setLineIndex] = useState<number>();



    return (
        <Grid container direction='column' rowGap={1.5}>
            <Grid item className='black font-weight-400 desktop-header-5'>Add a Tax Advisor Admin for the new Tax Advisory Company</Grid>
            <Grid item className='black'>Email <span style={{ color: '#EF4737' }}>*</span></Grid>
            <Grid container direction='row' alignItems='center' columnGap={1}>

            </Grid>
            {addedTAs?.map((t: any, index: any) => (
                <Grid container key={index} direction='row' alignItems='center' columnGap={1}>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            value={t}
                            InputLabelProps={{ shrink: true }}
                            error={lineIndex === index  ? emailError : false}
                            helperText={emailError && lineIndex === index ? emailErrorMsg : ''}
                         
                            InputProps={{
                                style: {
                                    borderRadius: '12px',
                                    fontFamily: 'DM Sans',
                                    height: '48px',
                                    borderTopLeftRadius: '10px',
                                    backgroundColor: '#F7F9FF',
                                },
                            }}

                            onChange={(e) => {
                                setLineIndex(index);
                                setAddedTAs((prevTAs: any) => {
                                    const newTAs = [...prevTAs]; // Create a shallow copy of the array
                                    newTAs[index] = e.target.value; // Update the value at the given index
                                    return newTAs; // Return the new array
                                });
                                forceUpdate();
                                if (!e.target.value || !e.target.value.match(
                                  /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                                )) {
                                  setEmailError(true);
                                  setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
                                }
                                else {
                                  setEmailError(false);
                                  setEmailErrorMsg('')
                                }
                              }}
                              onBlur={(e) => {
                                if (!e.target.value) {
                                  setEmailError(true);
                                  setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
                                }
                              }}

                            // onChange={(e) => {
                            //     setAddedTAs((prevTAs: any) => {
                            //         const newTAs = [...prevTAs]; // Create a shallow copy of the array
                            //         newTAs[index] = e.target.value; // Update the value at the given index
                            //         return newTAs; // Return the new array
                            //     });
                            //     forceUpdate();

                            // }}
                        />
                    </Grid>
                    <Grid item className='pointerText'
                        onClick={() =>
                            setAddedTAs(addedTAs?.filter((_: any, idx: any) => idx !== index))}
                    >
                        <img src={deleteIcon} width='20px' height='20px' />
                    </Grid>
                </Grid>

            ))}

            <Grid container alignItems='center' className='iris-100 bold'
                style={{ cursor:addedTAs?.includes("") ? 'unset':'pointer'}}
                onClick={() => {
                    setAddedTAs([...addedTAs, ""]);
                }}>
                <AddIcon htmlColor='#5D5FEF' fontSize='small' />
                Add User
            </Grid>

            <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1}>
                <Grid item xs={2.5} >
                    <button className='greyButton'
                        onClick={() => navigate('/admin-panel/users')}
                    ><Message id="button.cancel" className="Field-input labelsLinks labels-extra-bold" /></button>
                </Grid>
                <Grid item xs={2.5}>
                    <button
                        //disabled={addedTAs?.includes("") || emailError? true : false}
                        className='blueButton'
                        onClick={() => {
                            setActiveSection('Review')
                        }
                        }
                    >
                        <Message id="button.next" className="Field-input labelsLinks labels-extra-bold" />
                    </button>
                </Grid>
            </Grid>




        </Grid >

    );
}

export default AddTaxAdvisor
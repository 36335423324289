import React, { useState, useEffect, useRef } from 'react'
import { Box, Grid, MenuItem, TextField } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeIcon from "../../../assets/images/icons/homeIcon.png";
import Message from '../../../sharedComponents/ui/Message';
import classes from "../../../assets/styles/Task.module.css";
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import historyIcon from "../../../assets/images/icons/historyIcon.png";
import Messages from './Messages';
import History from './History';
import * as TaskServices from '../../../services/task-services.proxy';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getActionCallFrom, getCallStatus, getErrorMsg, setActionCallFrom, setCallStatus, setErrorMsg } from '../../../app/slices/apiCallSlice';
import { getLoadingState, setDialogLoading, setLoading } from '../../../app/slices/loadingSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import moment from 'moment';
import 'moment/locale/de';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'moment/locale/en-gb';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Attachments from './Attachments';
import ProfileImage from '../../../sharedComponents/ui/ProfileImage';
import * as StatusesConsts from "../../../status-consts";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { selectRoles, selectUserType } from '../../../app/slices/userDataSlice';
import CheckIcon from '@mui/icons-material/Check';
import CheckedIcon from '../../../assets/images/icons/CheckedIcon.png';
import UnCheckedIcon from '../../../assets/images/icons/UncheckedIcon.png';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import * as userServices from "../../../services/user-services.proxy";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteDialog from '../../../sharedComponents/Dialogs/DeleteDialog';
import * as userRolesConst from "../../../user-roles-consts";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import * as TaskConsts from "../../../task-consts";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import deleteIcon from "../../../assets/images/icons/delete.png";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useIntl } from 'react-intl';


const getUserProfile = userServices.getUserProfile;

export interface TaskProps {
  taskDetails: any;
  typesList: any;
  assigneesList: any;
  clientsList: any;
  statusesList: any;
  moduleList: any;
  historyCategoriesList: any;
  updateTaskCall: any;
  addCommentCall: any;
  commentLoading: any;
  commentTxt: any;
  setCommentTxt: any;
  handleFileChange: any;
  fileLoading: any;
  deleteTaskDocumentCall: any;
  uploadTaskDocumentCall: any;
  files: any;
  recurringPeriodsList: any;
  internalAssigneesList: any;
  externalAssigneesList: any;
  isFocused: any;
  setIsFocused: any;
  setIsFocused1 : any;
  isFocused2: any;
  setIsFocused2: any;
  moveDocumentToDatevCall: any;
  movingToDatevLoading: any;
  taskObject: any;
  setTaskObject: any;
  setOpen: any
  open: any;
  setOpenDeleteDialog: any;
  setselectedTaskToDelete: any;
  updateTaskStatusCall: any;
  commentFiles: any;
  setCommentFiles: any;
  remindersList: any;
  postReminderCall: any;
  inActiveReminderCall: any;
  setOpenCustomReminder: any;
  openCustomReminder: any;
  moveAttachmentsToDatevCall: any;
  commentReplyTxt : any;
  setCommentReplyTxt : any;
  commentReplyFiles : any;
  setCommentReplyFiles : any;

}

const TaskDetailsContainer = (props: TaskProps) => {



  const {
    taskDetails,
    typesList,
    assigneesList,
    clientsList,
    statusesList,
    moduleList,
    historyCategoriesList,
    updateTaskCall,
    addCommentCall,
    commentLoading,
    commentTxt,
    setCommentTxt,
    handleFileChange,
    fileLoading,
    deleteTaskDocumentCall,
    uploadTaskDocumentCall,
    files,
    recurringPeriodsList,
    internalAssigneesList,
    externalAssigneesList,
    isFocused,
    setIsFocused,
    setIsFocused1,
    isFocused2,
    setIsFocused2,
    moveDocumentToDatevCall,
    movingToDatevLoading,
    taskObject,
    setTaskObject,
    setOpen,
    open,
    setOpenDeleteDialog,
    setselectedTaskToDelete,
    updateTaskStatusCall,
    commentFiles,
    setCommentFiles,
    remindersList,
    postReminderCall,
    inActiveReminderCall,
    openCustomReminder,
    setOpenCustomReminder,
    moveAttachmentsToDatevCall,
    commentReplyTxt,
    setCommentReplyTxt,
    commentReplyFiles,
    setCommentReplyFiles
  } = props


  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState('Messages');
  const [iseRecuringTask, setIsRecuringTask] = useState(taskDetails?.recurringPeriod === 0 ? false : true);
  const prioritiesList = [1, 2, 3, 4, 5];
  const intl = useIntl();

  const [internalAssigneeSearch, setInternalAssigneeSearch]= useState('');
  const [externalAssigneeSearch, setExternalAssigneeSearch]= useState('');
  const [filteredExternalAssigneesList, setFilteredExternalAssigneesList]= useState(externalAssigneesList);
  const [filteredInternalAssigneesList, setFilteredInternalAssigneesList]= useState(internalAssigneesList);

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: '0px 2px 4px 0px #0000001F',
      backgroundColor: '#FEFEFF',
      borderRadius: '10px',
      padding: 0,
      color: 'black',
    },
  }));


  const navigate = useNavigate();
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => {
    // Updating state with an empty object to trigger a re-render
    updateState({});
  }, []);

  const loading = useAppSelector(getLoadingState);
  const errorMsg = useAppSelector(getErrorMsg)
  const callStatus = useAppSelector(getCallStatus);
  const userType = useAppSelector(selectUserType);
  const userRoles = useAppSelector(selectRoles);
  const actionCallFrom = useAppSelector(getActionCallFrom);
  const token = useAppSelector((state) => state.userData.token);

  const [openAssigneesDropdown, setOpenAssigneesDropdown] = useState(false);
  const [assigneesType, setAssigneesType] = useState('External');
  const [username, setUsername] = useState('');


  //reminder
  const [openRemindersDropdown, setOpenRemindersDropdown] = useState(false);
  const [reminderTypeId, setReminderTypeId] = useState(0);
  const [reminderDate, setReminderDate] = useState(null);
  const reminderObj = {
    taskId: taskDetails.id,
    date: reminderDate,
    typeId: reminderTypeId
  }

  const handleReminderDateChange = (newValue: any) => {
    console.log("checkk")
    if (!newValue) {
      setReminderDate(null)
      return;
    }
    const formattedDate = newValue.format('YYYY-MM-DD');
    setReminderDate(formattedDate);
    forceUpdate();
  }

  const handleCloseCustomReminder = () => {
    setOpenCustomReminder(false);
  };

  useEffect(() => {
    console.log("actionCallFrom", actionCallFrom)
  }, [actionCallFrom])

  const getUserProfileCall = () => {
    getUserProfile(token).then((x) => {
      setUsername(x.username);
    })
  }


  useEffect(() => {
    getUserProfileCall();
  }, [username]);

  // //Files
  // const [file, setFile] = useState(null);
  // const [files, setFiles] = useState<File[]>([]);
  // const [fileId, setFileId] = useState(null);
  // const [fileIds, setFileIds] = useState([]);

  const [dueDateError, setDueDateError] = useState(false);
  // const files = [
  //   {
  //     id: 1,
  //     name: "File Name 1",
  //     size: '14KB',
  //     date: "5/9/2024"
  //   },
  //   {
  //     id: 2,
  //     name: "File Name 2",
  //     size: '14KB',
  //     date: "5/9/2024"
  //   },

  // ]

  // useEffect(() => {
  //   if (file) {
  //     let tempFile = null;
  //     tempFile = file;
  //     files.push(tempFile);
  //   }
  // }, [file]);

  // useEffect(() => {
  //   if (fileId) {
  //     let tempFileId = null;
  //     tempFileId = fileId;
  //     let fileData = { FileId: tempFileId };
  //     fileIds.push(fileData);
  //     // if (mrId) MRUpperDataUpdate();
  //   }
  // }, [fileId]);

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const uploadedFiles = event.target.files;
  //   if (uploadedFiles) {
  //     // Handle the file upload logic here
  //     const filesArray = Array.from(uploadedFiles);
  //     setFiles(filesArray);
  //   }
  // };

  // const handleDateChange = (newValue: any) => {
  //   if (newValue && newValue.isValid()) {
  //     taskDetails.dueDate = newValue.format('YYYY-MM-DD');
  //     setDueDateError(false);
  //   } else {
  //     setDueDateError(true); // Trigger error state if the date is invalid
  //   }
  // };

  const handleDateChange = (newValue: any) => {
    if (newValue && newValue.isValid()) {
      taskDetails.dueDate = moment(newValue).format('YYYY-MM-DD');
      setDueDateError(false);
      if (taskDetails.status === StatusesConsts.OVERDUE_STATUS_ID) {
        taskDetails.status = StatusesConsts.IN_PROGRESS_STATUS_ID
        forceUpdate();
      }
    } else {
      setDueDateError(true); // Trigger error state if the date is invalid
    }
  };

  const handleStartDateChange = (newValue: any) => {
    if (newValue && newValue.isValid()) {
      taskDetails.startDate = moment(newValue).format('YYYY-MM-DD');
      // setDueDateError(false);
      // if (taskDetails.status === StatusesConsts.OVERDUE_STATUS_ID) {
      //   taskDetails.status = StatusesConsts.IN_PROGRESS_STATUS_ID
      //   forceUpdate();
      // }
    } else {
      // setDueDateError(true); // Trigger error state if the date is invalid
    }
  };

  const handleEndDateChange = (newValue: any) => {
    if (newValue && newValue.isValid()) {
      taskDetails.endDate = moment(newValue).format('YYYY-MM-DD');
      // setDueDateError(false);
      // if (taskDetails.status === StatusesConsts.OVERDUE_STATUS_ID) {
      //   taskDetails.status = StatusesConsts.IN_PROGRESS_STATUS_ID
      //   forceUpdate();
      // }
    } else {
      // setDueDateError(true); // Trigger error state if the date is invalid
    }
  };


  const handleRecuringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRecuringTask(event.target.checked);
  };

  const assgineeTriggerRef = useRef<HTMLDivElement | null>(null);
  const reminderTriggerRef = useRef<HTMLDivElement | null>(null);



  const handleClickOutside = (event: MouseEvent): void => {
    const tooltipElement = document.querySelector(".MuiTooltip-popper");
    const isClickInsideTooltip = tooltipElement?.contains(event.target as Node);
    const isClickInsideAssigneeTrigger = assgineeTriggerRef.current?.contains(event.target as Node);
    const isClickInsideReminderTrigger = reminderTriggerRef.current?.contains(event.target as Node);

    if (!isClickInsideTooltip && !isClickInsideAssigneeTrigger) {
      setOpenAssigneesDropdown(false);
    }

    if (!isClickInsideTooltip && !isClickInsideReminderTrigger) {
      setOpenRemindersDropdown(false);
    }

  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const gridRefComments = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (taskDetails?.notifications?.length > 0 && !taskDetails?.notifications?.find((n: any) => n.type === 2) && gridRefComments.current) {
      gridRefComments.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [taskDetails]);


  return (
    <>
      {
        taskDetails ?
          <>
            <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start'
            >
              <Grid item container direction='column' xs={8.3} rowGap={2}>
                <Grid item className='black desktop-header-5'>
                  {/* {taskDetails?.title} */}
                  <TextField
                    fullWidth
                    value={taskDetails?.title}
                    disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                    onChange={(e) => {
                      taskDetails.title = e.target.value
                      forceUpdate();
                    }}
                    sx={{
                      '& label.Mui-focused': {
                        color: '#C3C2C7',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#B2BAC2',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#C3C2C7',
                        },
                        '&:hover fieldset': {
                          borderColor: '#B2BAC2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#6F7E8C',
                        },
                      },
                    }}
                    InputProps={{ className: 'fieldDesign' }}
                  />
                </Grid>
                <Grid item columnGap={0.5} container direction='row' className='neutral-3' alignItems='center'>
                  {/* <span
                    style={{ cursor: 'pointer' , marginTop:'2px'}}
                    onClick={() => navigate('/users')}
                  > */}
                  {/* <HomeOutlinedIcon style={{ cursor: 'pointer', color: '#93919A' }}
                    onClick={() => navigate('/users')} fontSize='small' /> */}
                  <img src={HomeIcon} width='20px' height='20px' alt='icon' style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
                      else navigate('/tasks')
                    }
                    }
                  />

                  {/* </span> */}
                  <Message id="user.profile.home" className="Field-input desktop-paragraph neutral-3 pointerText"
                    onClick={() => {
                      if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
                      else navigate('/tasks')
                    }
                    }
                  /> / <Message id="task.tasks" className="Field-input desktop-paragraph neutral-3" />
                  {/* / <Message id="task.task-id" className="Field-input desktop-paragraph neutral-3 bold" /> */}
                </Grid>
                {/* <Grid item container rowGap={0.5} width='90%'>
          <span className='black labels-extra-bold'>Description</span>
          {taskDetails.title}
        </Grid> */}
                <Grid item container direction='column'>
                  <Grid item className='black font-14 labels-extra-bold' ><Message id="task.description" className='black font-14 labels-extra-bold' /></Grid>
                  <Grid item >
                    {/* {taskDetails?.title} */}
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      value={taskDetails?.description}
                      disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                      onChange={(e) => {
                        taskDetails.description = e.target.value
                        forceUpdate();
                      }}
                      sx={{
                        '& label.Mui-focused': {
                          color: '#C3C2C7',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#B2BAC2',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#C3C2C7',
                          },
                          '&:hover fieldset': {
                            borderColor: '#B2BAC2',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#6F7E8C',
                          },
                        },
                      }}
                      InputProps={{ className: 'descriptionDesign' }}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                </Grid>

                <Grid item container rowGap={0.5} margin={0}>

                  <Grid item container direction='row' columnGap={4} alignItems='center'>
                    <Grid item className='black font-14 labels-extra-bold' xs={1}><Message id="task.client" className="Field-input font-weight-700 font-14" /></Grid>
                    <Grid item className='labels'> {clientsList?.find((i: { id: any; }) => i.id === taskDetails?.clientId)?.name}
                    </Grid>
                  </Grid>
                  <Grid item container direction='row' columnGap={4} alignItems='center'>
                    <Grid item className='black font-14 labels-extra-bold' xs={1}><Message id="task.module" className="Field-input font-weight-700 font-14" /></Grid>
                    <Grid item>{moduleList?.find((i: any) => i.id === taskDetails.module)?.name}</Grid>
                  </Grid>
                  <Grid item container direction='row' columnGap={4} alignItems='center'>
                    <Grid item className='black font-14 labels-extra-bold' xs={1}><Message id="task.type" className="Field-input font-weight-700 font-14" /></Grid>
                    <Grid item className={classes['moduleBox']}>
                      {/* {taskDetails?.type} */}
                      {typesList?.find((i: { id: any; }) => i.id === taskDetails?.type)?.name}
                    </Grid>

                  </Grid>
                </Grid>
                <Grid item container className='whiteContainer'>
                  <Attachments
                    taskDetails={taskDetails}
                    handleFileChange={handleFileChange}
                    files={files}
                    fileLoading={fileLoading}
                    deleteTaskDocumentCall={deleteTaskDocumentCall}
                    uploadTaskDocumentCall={uploadTaskDocumentCall}
                    moveAttachmentsToDatevCall={moveAttachmentsToDatevCall}
                    movingToDatevLoading={movingToDatevLoading}
                  />

                </Grid>
                <Grid item container className={classes['CommentContainer']}>
                  <Grid item container direction='row'>
                    <Grid item container width='188px' columnGap={1} alignItems='center' padding='15px'
                      //  className='black font-14 labels-extra-bold' 
                      className={classes[activeTab === 'Messages' ? 'messagesTabActive' : '']}
                      style={{ cursor: activeTab === 'Messages' ? 'unset' : 'pointer' }}
                      onClick={() => {
                        setActiveTab('Messages')
                      }}
                    > <ChatOutlinedIcon htmlColor='#93919a' style={{ width: '24px', height: '24px' }} /> <Message id="task.messages" className={`Field-input font-14 ${activeTab === 'Messages' ? 'bold' : 'normal'}`} />    <span className='nbCircle'>{taskDetails?.comments?.length}</span> </Grid>
                    <Grid item container width='180px'
                      style={{ cursor: activeTab === 'History' ? 'unset' : 'pointer' }}
                      columnGap={1} alignItems='center' padding='15px' className={classes[activeTab === 'History' ? 'historyTabActive' : '']}
                      onClick={() => {
                        setActiveTab('History')
                      }}
                    >
                      <img src={historyIcon} alt='icon' style={{ width: '24px', height: '24px' }} />
                      <Message id="task.history" className={`Field-input font-14 ${activeTab === 'History' ? 'bold' : 'normal'}`} />
                    </Grid>
                  </Grid>
                  <Grid item container padding={2}>
                    {activeTab === 'Messages' ?
                      <Messages
                        taskDetails={taskDetails}
                        addCommentCall={addCommentCall}
                        moveDocumentToDatevCall={moveDocumentToDatevCall}
                        movingToDatevLoading={movingToDatevLoading}
                        commentLoading={commentLoading}
                        commentTxt={commentTxt}
                        commentFiles={commentFiles}
                        commentReplyTxt = {commentReplyTxt}
                        setCommentReplyTxt = {setCommentReplyTxt}
                        commentReplyFiles = {commentReplyFiles}
                        setCommentReplyFiles = {setCommentReplyFiles}
                        setCommentFiles={setCommentFiles}
                        setCommentTxt={setCommentTxt}
                        internalAssigneesList={internalAssigneesList}
                        externalAssigneesList={externalAssigneesList}
                        isFocused={isFocused}
                        setIsFocused={setIsFocused}
                        setIsFocused1={setIsFocused1}
                        isFocused2={isFocused2}
                        setIsFocused2={setIsFocused2}
                        username={username}
                        gridRefComments={gridRefComments}
                      />

                      :
                      <History taskDetails={taskDetails} historyCategoriesList={historyCategoriesList} />
                    }
                  </Grid>

                </Grid>
                <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1}>
                  <Grid item xs={3}
                    onClick={() => {
                      if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
                      else navigate('/tasks')
                    }}>
                    <button className='greyButton'><Message id="button.cancel" className='labels-extra-bold Field-input labelsLinks' /></button>
                  </Grid>
                  <Grid item xs={3}>
                    <button
                      className={actionCallFrom === 'Apply' ? callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton' : 'blueButton'}
                      disabled={loading || !taskDetails?.title || taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                      onClick={() => {
                        dispatch(setActionCallFrom('Apply'))
                        updateTaskCall()
                      }}>
                      {loading && actionCallFrom === 'Apply' ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                        : callStatus === 'Pass' && actionCallFrom === 'Apply' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                          : callStatus === 'Fail' && actionCallFrom === 'Apply' ? "Failed"
                            : <Message id="button.apply" className='labels-extra-bold Field-input labelsLinks' />}
                    </button>
                    <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={3.5} className='whiteContainer' rowGap={3} justifyContent='center' alignItems='center'>
                <Grid item xs={6}>
                  <span className='labels-extra-bold'><Message id="user.status" className='Field-input font-weight-700 font-14' /></span>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={taskDetails?.status}
                    disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                    select
                    onChange={(e) => {
                      taskDetails.status = e.target.value
                      forceUpdate();
                      updateTaskStatusCall(taskDetails.id, e.target.value)
                    }}

                    sx={{
                      '& label.Mui-focused': {
                        color: '#00B69B',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#B2BAC2',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                        },
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 280, // Set the max height of the dropdown
                            borderRadius: '20px', // Apply border-radius to the dropdown container
                          },
                        },
                      },
                    }}
                    InputProps={{ className: taskDetails.status === StatusesConsts.NEW_STATUS_ID ? 'statusFieldDesign' : taskDetails.status === StatusesConsts.OPEN_STATUS_ID ? 'OpenStatusFieldDesign' : taskDetails.status === StatusesConsts.OVERDUE_STATUS_ID ? 'OverdueStatusFieldDesign' : taskDetails.status === StatusesConsts.IN_PROGRESS_STATUS_ID ? 'InProgressStatusFieldDesign' : taskDetails.status === StatusesConsts.CLOSED_STATUS_ID ? 'ClosedStatusFieldDesign' : taskDetails.status === StatusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? 'WaitingForOthersStatusFieldDesign' : taskDetails.status === StatusesConsts.UPCOMING_STATUS_ID ? 'UpcomingStatusFieldDesign' : '' }}
                  >
                    {statusesList?.map((option: any) => (
                      <MenuItem key={option.id} value={option.id} disabled={option.id !== StatusesConsts.IN_PROGRESS_STATUS_ID && option.id !== StatusesConsts.WAITING_FOR_OTHERS_STATUS_ID}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>

                </Grid>
                <Grid item xs={6}>
                  <span className='labels-extra-bold'><Message id="task.assignee" className='Field-input font-weight-700 font-14' /></span>
                </Grid>
                <Grid item xs={1} textAlign='right' marginRight={0.5}><AccountCircleIcon style={{ width: '40px', height: '40px' }} /></Grid>

                <Grid item container xs={4.8}>
                  <HtmlTooltip
                    open={openAssigneesDropdown}
                    disableFocusListener
                    disableHoverListener
                    title={
                      <React.Fragment>
                        <Grid item container maxHeight='160px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                          <Grid item container direction='row' columnGap={2} marginBottom={1.5} justifyContent='center'>
                            <Grid item className={classes[assigneesType === 'Internal' ? "internal-external-active" : "internal-external-button"]}
                              ref={assgineeTriggerRef}
                              onClick={(e) => {
                                e.stopPropagation();
                                setAssigneesType('Internal')
                              }}>Internal</Grid>
                            <Grid item className={classes[assigneesType === 'External' ? "internal-external-active" : "internal-external-button"]}
                              ref={assgineeTriggerRef}
                              onClick={(e) => {
                                e.stopPropagation();

                                setAssigneesType('External')
                              }
                              }>External</Grid>
                          </Grid>
                          {/* <Grid item>
                          
                            <TextField
                              value={assigneesType==="External"?externalAssigneeSearch:internalAssigneeSearch}
                              fullWidth
                              InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                                style: {
                                  borderRadius: '35px',
                                  fontFamily: 'DM Sans',
                                  height: '32px',
                                  backgroundColor: '#F7F9FF',
                                  // border: '1px solid #E6E6E8'
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "#E6E6E8", // Sets the border color
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#E6E6E8", // Ensures border color on hover
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#E6E6E8", // Ensures border color when focused
                                  },
                                },
                                "& .MuiInputBase-input::placeholder": {
                                  fontWeight: "normal",
                                },
                              }}
                              placeholder={intl.formatMessage({ id: 'text.search' })}
                              onChange={(e) => {
                                const searchTerm = e.target.value;
                                if (assigneesType === 'External') {
                                  setExternalAssigneeSearch(searchTerm);
                                  if (searchTerm.trim() === '') {
                                    setFilteredExternalAssigneesList(externalAssigneesList);
                                  } else {
                                    const filtered = externalAssigneesList.filter((externalAssignee: any) => 
                                      externalAssignee.name.toLowerCase().includes(searchTerm.toLowerCase())
                                    );
                                    setFilteredExternalAssigneesList(filtered);
                                  }
                                } else {
                                  setInternalAssigneeSearch(searchTerm);
                                  if (searchTerm.trim() === '') {
                                    setFilteredInternalAssigneesList(internalAssigneesList);
                                  } else {
                                    const filtered = internalAssigneesList.filter((internalAssignee: any) => 
                                      internalAssignee.name.toLowerCase().includes(searchTerm.toLowerCase())
                                    );
                                    setFilteredInternalAssigneesList(filtered);
                                  }
                                }
                              }}
                            />
                          </Grid> */}
                          <Grid item container direction="column" rowGap={1} padding={0.5} marginTop={1}>

                            {assigneesType === 'Internal' ? filteredInternalAssigneesList?.map((i: any, index: any) => (
                              <Grid item container alignItems='center' key={i.id} columnGap={1}
                                className="font-14 normalFontWeight"
                                // className={classes[i.id === taskDetails.assigneeId ? 'filter-selected' : 'filter-not-selected']}
                                justifyContent='flex-start'
                                onMouseOver={(e) => {
                                  const target = e.target as HTMLElement;
                                  target.style.cursor = 'pointer';
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  taskDetails.assigneeId = i.id
                                  forceUpdate();
                                  setOpenAssigneesDropdown(false);
                                }}
                              >
                                
                                <Grid className='bordered-box'
                                  style={{ borderColor: i.id === taskDetails?.assigneeId ? '#fff' : '#E6E6E8' }}
                                  bgcolor={i.id === taskDetails?.assigneeId ? '#CCF0EB' : '#fff'}>
                                  {
                                    i.id === taskDetails?.assigneeId ?
                                      // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '13px', height: '12px' }} />
                                      <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                      :
                                    <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  }
                                  {/* <CheckIcon fontSize='small' htmlColor={viewers.find((v: string) => v === i.id) ? '#00B69B' : '#9E9E9E'} />
                                     */}</Grid>
                                {/* <Grid className='bordered-box' bgcolor={i.id === taskDetails?.assigneeId ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={i.id === taskDetails?.assigneeId ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                                <Grid item>{i.name}</Grid>
                              </Grid>
                            )) :
                            filteredExternalAssigneesList?.map((i: any) => (
                                <Grid item container alignItems='center' justifyContent='flex-start' key={i.id} columnGap={1}
                                  className="font-14 normalFontWeight"
                                  // className={classes[i.id === taskDetails?.assigneeId ? 'filter-selected' : 'filter-not-selected']}
                                  onMouseOver={(e) => {
                                    const target = e.target as HTMLElement;
                                    target.style.cursor = 'pointer';
                                  }}
                                  onClick={(e) => {
                                    taskDetails.assigneeId = i.id
                                    forceUpdate();
                                    setOpenAssigneesDropdown(false);
                                  }}
                                >
                                  
                                  <Grid className='bordered-box'
                                    style={{ borderColor: i.id === taskDetails?.assigneeId ? '#fff' : '#E6E6E8' }}
                                    bgcolor={i.id === taskDetails?.assigneeId ? '#CCF0EB' : '#fff'}>
                                    {
                                      i.id === taskDetails?.assigneeId ?
                                        // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '13px', height: '12px' }} />
                                        <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                              :
                            <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                    }
                                    {/* <CheckIcon fontSize='small' htmlColor={viewers.find((v: string) => v === i.id) ? '#00B69B' : '#9E9E9E'} />
                                     */}</Grid>
                                  {/* <Grid className='bordered-box' bgcolor={i.id === taskDetails?.assigneeId ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={i.id === taskDetails?.assigneeId ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                                  <Grid item>{i.name}</Grid>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                  >

                    <Grid item container justifyContent='space-between'
                      alignItems='center' textAlign='center' width='100%'
                      // className='fieldDesign'
                      //  style={{ border: 'solid 1px #C3C2C7', cursor: taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID ? 'pointer' : 'unset' }}
                      style={{ cursor: taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID ? 'pointer' : 'unset' }}
                      paddingRight={0.8}
                      paddingLeft={1.5}
                      direction='row'
                      ref={assgineeTriggerRef}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID)
                          setOpenAssigneesDropdown(!openAssigneesDropdown)
                      }}>
                      <Grid item style={{ flexGrow: 1, maxWidth: 100, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{internalAssigneesList?.find((i: any) => i.id === taskDetails?.assigneeId)?.name} {externalAssigneesList?.find((i: any) => i.id === taskDetails?.assigneeId)?.name}</Grid>
                      <Grid item textAlign='right' marginTop={1}>
                        {openAssigneesDropdown ? <ArrowDropUpIcon htmlColor='#6F7E8C' />
                          :
                          <ArrowDropDownIcon htmlColor='#6F7E8C' />
                        }
                      </Grid>
                    </Grid>

                  </HtmlTooltip>
                  {/* <Box display="flex" alignItems="center">
                  <AccountCircleIcon style={{ width: '40px', height: '40px' }} />
                  <Grid item width='100%' xs={9}>
                    <TextField
                      fullWidth
                      select
                      value={taskDetails?.assigneeId}
                      defaultValue={taskDetails?.assigneeId}
                      onChange={(e) => {
                        taskDetails.assigneeId = e.target.value
                        forceUpdate();
                      }}
                      sx={{
                        '& label.Mui-focused': {
                          color: 'white',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: 'white',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'white  ',
                          },
                        },
                      }}
                      InputProps={{ className: 'fieldDesign' }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 150, // Set the max height of the dropdown
                            },
                          },
                        },
                      }}
                    >
                      {assigneesList?.map((option: any) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Box> */}
                </Grid>

                <Grid item xs={6}>
                  <span className='Field-input font-weight-700 font-14'>
                    <Message id="task.start-date" className='Field-input font-weight-700 font-14' />
                  </span>
                </Grid>
                <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
                    <DatePicker
                      shouldDisableDate={(date) =>
                        date.isBefore(moment(), 'day')
                      }
                      value={taskDetails.startDate ? moment(taskDetails.startDate, 'YYYY-MM-DD') : null}
                      onChange={handleStartDateChange}
                      format="DD.MM.YYYY"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: dueDateError,
                          sx: {
                            '& label.Mui-focused': {
                              color: 'white',
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: 'white',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: dueDateError ? 'red' : 'white',
                                borderRadius: '8px'
                              },
                              '&:hover fieldset': {
                                borderColor: 'white',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'white',
                              },
                            },
                          },
                          size: "small",
                          variant: "outlined"
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={6}>
                  <span className='Field-input font-weight-700 font-14'>
                    End Date
                    {/* <Message id="task.due-date" className='Field-input font-weight-700 font-14' />  */}
                  </span>
                </Grid>
                <Grid item xs={6}>


                  <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
                    <DatePicker
                      disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                      shouldDisableDate={(date) =>
                        date.isBefore(moment(), 'day')
                      }
                      value={taskDetails.endDate ? moment(taskDetails.endDate, 'YYYY-MM-DD') : null}
                      onChange={handleEndDateChange}
                      format="DD.MM.YYYY"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          // error: dueDateError,
                          sx: {
                            '& label.Mui-focused': {
                              color: 'white',
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: 'white',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                // borderColor: dueDateError ? 'red' : 'white',
                                borderRadius: '8px'
                              },
                              '&:hover fieldset': {
                                borderColor: 'white',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'white',
                              },
                            },
                          },
                          size: "small",
                          variant: "outlined"
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={6}>
                  <span className='labels-extra-bold'><Message id="task.due-date" className='Field-input font-weight-700 font-14' /> </span>
                </Grid>
                <Grid item xs={6}>
                  {/* <TextField
            fullWidth
            value={taskDetails?.dueDate}
            onChange={(e) => {
              taskDetails.dueDate = e.target.value
              forceUpdate();
            }}
            InputLabelProps={{
              shrink: false,
            }}
            sx={{
              '& label.Mui-focused': {
                color: 'white',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: 'white',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white  ',
                },
              },
            }} size="small"
            label={""}
            type={"date"}
            variant="outlined"
          /> */}


                  <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
                    <DatePicker
                      disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                      shouldDisableDate={(date) =>
                        date.isBefore(moment(), 'day')
                      }
                      value={taskDetails.dueDate ? moment(taskDetails.dueDate, 'YYYY-MM-DD') : null}
                      onChange={handleDateChange}
                      format="DD.MM.YYYY"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: dueDateError,
                          sx: {
                            '& label.Mui-focused': {
                              color: 'white',
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: 'white',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: dueDateError ? 'red' : 'white',
                                borderRadius: '8px'
                              },
                              '&:hover fieldset': {
                                borderColor: 'white',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'white',
                              },
                            },
                          },
                          size: "small",
                          variant: "outlined"
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

                {/* <Grid item xs={6}>
                <span className='labels-extra-bold font-14'><Message id="task.module" className='labels-extra-bold font-14' /></span>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  select
                  value={taskDetails.module}
                  disabled
                  onChange={(e) => {
                    taskDetails.module = parseInt(e.target.value)
                    forceUpdate();
                  }}
                  sx={{
                    '& label.Mui-focused': {
                      color: 'white',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: 'white',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'white',
                      },
                      '&:hover fieldset': {
                        borderColor: 'white',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'white  ',
                      },
                    },
                  }}
                  InputProps={{ className: 'fieldDesign' }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 150, 
                        },
                      },
                    },
                  }}
                >
                  {moduleList?.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>


              </Grid> */}

                <Grid item xs={6}>
                <span className='labels-extra-bold'><Message id="text.reminder" className='Field-input font-weight-700 font-14' /> </span>
                </Grid>


                <Grid item xs={6}>
                  <HtmlTooltip
                    open={openRemindersDropdown}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <Grid item container rowGap={2} padding={2} maxWidth='270px' className='font-14 font-weight-400 black'>
                          {remindersList?.map((r: any) => (
                            <>
                              <Grid item key={r.id}
                                className={taskDetails?.reminders?.length > 0 && taskDetails?.reminders[0]?.typeId === r.id ? 'bold pointerUnset' : 'normalFontWeight pointerText'}
                                onClick={() => {
                                  setReminderTypeId(r.id);
                                  let tmp = {
                                    taskId: taskDetails.id,
                                    date: null,
                                    typeId: r.id
                                  }

                                  if (taskDetails?.reminders?.length === 0) {
                                    postReminderCall(tmp, null)
                                  }
                                  else postReminderCall(tmp, taskDetails?.reminders[0]?.id)
                                  setOpenRemindersDropdown(!openRemindersDropdown)

                                }}
                              >
                                {r.name === 'Tomorrow' ? <Message id="task.remind-tomorrow" className={taskDetails?.reminders?.length > 0 && taskDetails?.reminders[0]?.typeId === r.id ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} /> :
                                  r.name === 'InTwoDays' ? <Message id="task.remind-in-2-days" className={taskDetails?.reminders?.length > 0 && taskDetails?.reminders[0]?.typeId === r.id ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} /> :
                                    r.name === 'TwoDaysBeforeDue' ? <Message id="task.remind-before-duedate" className={taskDetails?.reminders?.length > 0 && taskDetails?.reminders[0]?.typeId === r.id ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} /> :
                                      ""}

                              </Grid>
                              {r.name === 'Custom' ?
                                <Grid item
                                  className={taskDetails?.reminders?.length > 0 && taskDetails?.reminders[0]?.typeId === r.id ? 'bold pointerText' : 'normalFontWeight pointerText'}
                                  ref={reminderTriggerRef}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (taskDetails?.reminders?.length > 0 && taskDetails?.reminders[0]?.typeId) setReminderDate(taskDetails?.reminders[0].date)
                                    // if (taskDetails.reminders.length === 0) {
                                    setReminderTypeId(r.id);
                                    setOpenRemindersDropdown(!openRemindersDropdown)
                                    setOpenCustomReminder(true)
                                  }
                                    // }
                                  }
                                >
                                  {taskDetails?.reminders?.length > 0 && taskDetails?.reminders[0].typeId === r.id ?
                                    <Message id="task.remind-before-duedate" className='Field-input font-14' />  : <Message id="task.change-custom.reminder" className='Field-input font-14' /> }

                                </Grid> : ""}
                            </>

                          ))}

                          <Grid item
                            className={taskDetails.reminders.length > 0 ? 'pointerText' : 'pointerUnset'}
                            onClick={() => {
                              if (taskDetails.reminders.length > 0) {
                                inActiveReminderCall(taskDetails.reminders[0].id);
                                setOpenRemindersDropdown(!openRemindersDropdown)
                              }
                            }
                            }
                          >
                            <Message id="task.remove-reminder" className={taskDetails?.reminders?.length > 0 ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} /></Grid>
                        </Grid>
                      </React.Fragment>
                    }
                  >

                    <Grid item container justifyContent='space-between'
                      alignItems='center' textAlign='center' width='100%'
                      style={{ cursor: taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID ? 'pointer' : 'unset' }}
                      paddingRight={0.8}
                      paddingLeft={1.5}
                      direction='row'
                      className='fieldDesign'
                      color={taskDetails.reminders.length === 0 ? 'black' : '#6853A9'}
                      bgcolor={taskDetails.reminders.length === 0 ? '#C3C2C7' : '#EAE9FC'}
                      onClick={() => {
                        if (taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID)
                          setOpenRemindersDropdown(!openRemindersDropdown)
                      }}>
                      <Grid item style={{ flexGrow: 1, maxWidth: 160, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        className='bold'>
                        {taskDetails.reminders.length === 0 ?
                          <><Message id="task.no-reminder" className='Field-input font-weight-700 mobile-paragraph' /> &nbsp; <NotificationsOffOutlinedIcon fontSize='small' /></>
                          :
                          taskDetails.reminders[0]?.typeId === TaskConsts.TOMORROW_REMINDER_ID ?
                            <><Message id="task.tomorrow" className='Field-input font-weight-700 mobile-paragraph' />  &nbsp; <NotificationsActiveOutlinedIcon fontSize='small' /> </>
                            :
                            taskDetails.reminders[0]?.typeId === TaskConsts.IN_TWO_DAYS_REMINDER_ID ?
                              <><Message id="task.2-days" className='Field-input font-weight-700 mobile-paragraph' />  &nbsp; <NotificationsActiveOutlinedIcon fontSize='small' /> </>
                              :
                              taskDetails.reminders[0]?.typeId === TaskConsts.TWO_DAYS_BEFORE_DUE_REMINDER_ID ?
                                <><Message id="task.2-days-before" className='Field-input font-weight-700 mobile-paragraph' />  &nbsp; <NotificationsActiveOutlinedIcon fontSize='small' /> </>
                                :
                                taskDetails.reminders[0]?.typeId === TaskConsts.CUSTOM_REMINDER_ID ?
                                  <><Message id="task.custom" className='Field-input font-weight-700 mobile-paragraph' />  &nbsp; <NotificationsActiveOutlinedIcon fontSize='small' /> </>
                                  :
                                  ''
                        }
                      </Grid>
                      <Grid item textAlign='right' marginTop={1}>
                        {openRemindersDropdown ?
                          <ArrowDropUpIcon htmlColor={taskDetails.reminders.length === 0 ? 'black' : '#6853A9'} />
                          :
                          <ArrowDropDownIcon htmlColor={taskDetails.reminders.length === 0 ? 'black' : '#6853A9'} />
                        }
                      </Grid>
                    </Grid>

                  </HtmlTooltip>

                </Grid>

                <Grid item xs={6}>
                  <span className='labels-extra-bold font-14'><Message id="task.priority" className='labels-extra-bold font-14' /></span>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    value={taskDetails?.priority}
                    disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                    defaultValue={3}
                    onChange={(e) => {
                      taskDetails.priority = e.target.value
                      forceUpdate();
                    }}
                    sx={{
                      '& label.Mui-focused': {
                        color: 'white',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: 'white',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white',
                        },
                        '&:hover fieldset': {
                          borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white  ',
                        },
                      },
                    }}
                    InputProps={{ className: 'fieldDesign' }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 210, // Set the max height of the dropdown
                            borderRadius:'20px'
                          },
                        },
                      },
                    }}
                  >
                    {prioritiesList?.map((option: any) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>


                </Grid>

                <Grid item xs={6}>
                  <span className='labels-extra-bold'><Message id="task.recurring-task" className='Field-input font-weight-700 font-14' /></span>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    checked={iseRecuringTask}
                    disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                    control={<IOSSwitch sx={{ m: 1 }}
                      onChange={handleRecuringChange}
                    />}
                    label=""
                  />
                </Grid>
                {iseRecuringTask ?
                  <>
                    <Grid item xs={6}>
                      <span className='labels-extra-bold'><Message id="task.recurring-period" className='Field-input font-weight-700 font-14' /></span>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        select
                        value={taskDetails?.recurringPeriod}
                        disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                        onChange={(e) => {
                          taskDetails.recurringPeriod = e.target.value
                          forceUpdate();
                        }}
                        sx={{
                          '& label.Mui-focused': {
                            color: 'white',
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: 'white',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'white',
                            },
                            '&:hover fieldset': {
                              borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'white  ',
                            },
                          },
                        }}
                        InputProps={{ className: 'fieldDesign' }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 180, // Set the max height of the dropdown
                                borderRadius: '20px'
                              },
                            },
                          },
                        }}
                      >
                        {recurringPeriodsList?.map((option: any) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </>
                  : <></>}

                <Grid item container direction='row' alignItems='flex-start'>
                  <Grid item xs={6}>
                    <span className='labels-extra-bold'><Message id="task.note" className='Field-input font-weight-700 font-14' /></span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                      multiline
                      rows={3}
                      value={taskDetails?.note}
                      onChange={(e) => {
                        taskDetails.note = e.target.value
                        forceUpdate();
                      }}
                      sx={{
                        '& label.Mui-focused': {
                          color: '#C3C2C7',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#B2BAC2',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#C3C2C7',
                          },
                          '&:hover fieldset': {
                            borderColor: '#B2BAC2',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#6F7E8C',
                          },
                        },
                      }}
                      InputProps={{ className: 'descriptionDesign' }}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}></Grid>

                <Grid item container direction='row' xs={6} alignItems='center'>
                  <Grid item xs={11}>
                    <button
                      className={actionCallFrom === 'CompleteTask' ? callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton' : 'blueButton'}
                      style={{ padding: '20px' }}
                      disabled={loading}
                      onClick={() => {
                        // if (taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID) {
                        // taskDetails.markedAsDone = true;
                        // taskDetails.status = StatusesConsts.CLOSED_STATUS_ID
                        dispatch(setActionCallFrom('CompleteTask'))
                        // forceUpdate();
                        // updateTaskCall();
                        if (taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID) updateTaskStatusCall(taskDetails.id, StatusesConsts.CLOSED_STATUS_ID)
                        if (taskDetails.status === StatusesConsts.CLOSED_STATUS_ID) updateTaskStatusCall(taskDetails.id, StatusesConsts.OPEN_STATUS_ID)

                        // }
                        // else if (taskDetails.status === StatusesConsts.CLOSED_STATUS_ID) {
                        //   taskDetails.status = StatusesConsts.OPEN_STATUS_ID
                        //   forceUpdate();
                        //   dispatch(setActionCallFrom('CompleteTask'))
                        //   updateTaskCall();
                        // }

                      }}
                    >
                      {loading && actionCallFrom === 'CompleteTask' ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                        : callStatus === 'Pass' && actionCallFrom === 'CompleteTask' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                          : callStatus === 'Fail' && actionCallFrom === 'CompleteTask' ? "Failed"
                            :
                            taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID ?
                              <><CheckCircleOutlineOutlinedIcon /><Message id="task.complete-task" className='Field-input font-weight-700 labelsLinks' /></>
                              :
                              <><CheckCircleOutlineOutlinedIcon /><Message id="task.open-task" className='Field-input font-weight-700 labelsLinks' /></>
                      }
                    </button>
                  </Grid>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Grid item container rowGap={1.5} padding={1.5} maxWidth='130px'>
                          {userRoles?.includes(userRolesConst.ADMIN_ID)
                            || userRoles?.includes(userRolesConst.TAX_ADVISOR_ID)
                            || userRoles?.includes(userRolesConst.CLIENT_ADMIN_ID) ?
                            <Grid item container direction='row' className='red labels pointerText' alignItems='center' columnGap={1}
                              onClick={() => {
                                setselectedTaskToDelete(() => ({
                                  id: taskDetails.id,
                                  title: taskDetails.title
                                }));
                                setOpenDeleteDialog(true);
                              }}
                            >
                              <img src={deleteIcon} width='20px' height='20px' />
                              <Message id="ta.delete" className="Field-input labelsLinks font-weight-400" />

                              {/* <DeleteOutlineIcon htmlColor='#EF4737' fontSize='small' /> */}
                            </Grid>
                            :
                            <></>
                          }
                          <Grid item container direction='row' className='neutral-4 labels pointerText' alignItems='center' columnGap={1}
                            onClick={() => {
                              setTaskObject(() => ({
                                title: `${taskDetails.title} (copy)`,
                                status: 1,
                                description: taskDetails.description,
                                note: taskDetails.note,
                                recurringPeriod: taskDetails.recurringPeriod,
                                priority: taskDetails.priority,
                                dueDate: taskDetails.dueDate ? moment(taskDetails.dueDate).format('YYYY-MM-DD') : null,
                                type: taskDetails.type,
                                clientId: taskDetails.clientId,
                                assigneeId: taskDetails.assigneeId,
                                module: taskDetails.module
                              }))
                              setOpen(true);
                            }}
                          >
                            <ContentCopyOutlinedIcon htmlColor='#605e6b' width='15px' height='15px' style={{ width: '18px', height: '18px' }} /> <Message id="task.duplicate" className="Field-input labelsLinks font-weight-400"  />
                          </Grid>

                        </Grid>
                      </React.Fragment>
                    }
                  >
                    <Grid item style={{ cursor: 'pointer' }} xs={1}><MoreVertIcon htmlColor='#93919a' /></Grid>
                  </HtmlTooltip>
                </Grid>

                {/* <Grid item xs={6}>
          <span className='labels-extra-bold'><Message id="task.relevant-period" className='Field-input font-weight-700 font-14' /></span>
        </Grid>
        <Grid item xs={6}>
          N/A
        </Grid>
        <Grid item xs={6}>
          <span className='labels-extra-bold'><Message id="task.priority" className='Field-input font-weight-700 font-14' /></span>
        </Grid>
        <Grid item xs={6}>
          N/A
        </Grid>
       */}

              </Grid>

            </Grid >

            <Dialog
              open={openCustomReminder}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleCloseCustomReminder}
              aria-describedby="alert-dialog-slide-description"
              PaperProps={{ sx: { borderRadius: "8px", width: '340px' } }}

            >
              <Grid item container direction='column' padding={3} rowGap={1} justifyContent='center' textAlign='center'>
                <Grid item container direction='row' justifyContent='space-between'>
                  <Grid item>Set Custom Reminder </Grid>
                  <Grid item style={{ cursor: 'pointer' }} onClick={() => setOpenCustomReminder(false)}><CloseIcon /></Grid>
                </Grid>
                <Grid item className='greyLine' width='100%'></Grid>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
                  <DateCalendar
                    views={['day']}
                    value={reminderDate ? moment(reminderDate, 'YYYY-MM-DD') : null}
                    onChange={handleReminderDateChange}
                    slotProps={{ calendarHeader: { sx: { color: 'black' } } }}
                    sx={{ width: '300px' }}
                  />
                </LocalizationProvider>
                <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1.5}>
                  {errorMsg ?
                    <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
                    :
                    <></>

                  }
                  <Grid item xs={3} >
                    <button className='greyButton' onClick={() => setOpenCustomReminder(false)}><Message id="button.cancel" className="Field-input labelsLinks labels-extra-bold" /></button>
                  </Grid>
                  <Grid item xs={5}>
                    <button
                      className={actionCallFrom === 'post reminder' ? callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton' : 'blueButton'}
                      disabled={loading || !reminderDate}
                      onClick={() => {
                        if (taskDetails.reminders?.length === 0) {
                          postReminderCall(reminderObj, null)
                        }
                        else postReminderCall(reminderObj, taskDetails.reminders[0]?.id)
                      }
                      }
                    >
                      {loading && actionCallFrom === 'post reminder' ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                        : callStatus === 'Pass' && actionCallFrom === 'post reminder' ? <CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} />
                          : callStatus === 'Fail' && actionCallFrom === 'post reminder' ? "Failed"
                            : "Set Reminder"}
                    </button>
                  </Grid>
                </Grid>


              </Grid>
            </Dialog>
          </>
          :
          <></>
      }
    </>

  )
}

export default TaskDetailsContainer;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});
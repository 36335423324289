import React, { useState, useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Grid, MenuItem, TextField, Backdrop, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Message from '../../sharedComponents/ui/Message';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getLoadingState, setLoading } from '../../app/slices/loadingSlice';
import { getCallStatus, getErrorMsg, setCallStatus, setErrorMsg } from '../../app/slices/apiCallSlice';
import * as userServices from '../../services/user-services.proxy';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ForgotPass from './ForgotPass';
import { useLocation } from 'react-router-dom';
import * as authServices from ".././../services/auth-services.proxy";
import { useIntl } from 'react-intl';


const changePassword = userServices.changePassword;
const resetPassword = authServices.resetPassword;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export interface DialogProps {
    open: boolean;
    setOpen: CallableFunction;

}

const ChangePass = (props: DialogProps) => {

    const { open, setOpen, } = props;
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState<boolean>(false);
    const [emailErrorMsg, setEmailErrorMsg] = useState<string>('');
    const [currentPass, setCurrentPass] = useState("");
    const [newPass, setNewPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const loading = useAppSelector(getLoadingState);
    const errorMsg = useAppSelector(getErrorMsg);
    const callStatus = useAppSelector(getCallStatus);
    const [passMismatch, setPassMismatch] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const [openForgotPass, setOpenForgotPass] = useState(false);

    const useQuery = () => {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    };

    const query = useQuery();
    const token = query.get('token');


    const dispatch = useAppDispatch();
    const intl = useIntl();


    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setNewPass("");
        setConfirmPass("");
        setCurrentPass("");
        setPassMismatch(false);
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            handleOpen();
        }
        else {
            setOpen(false);

        }
    }, [open]);

    const changePassCall = () => {
        dispatch(setLoading(true));
        changePassword(currentPass, newPass).then((x) => {
            console.log("x", x)
            if (x.ErrorMessage) {
                dispatch(setCallStatus('Fail'));
                dispatch(setErrorMsg(x.ErrorMessage))
            }
            else {
                dispatch(setCallStatus('Pass'));
            }
            setTimeout(() => {
                dispatch(setCallStatus(''));
                dispatch(setErrorMsg(''));
                if (!x.ErrorMessage) handleClose();
            }, 4500)
            dispatch(setLoading(false));

        })
    }

    const resetPassCall = () => {
        if (token) {
            dispatch(setLoading(true));
            resetPassword(email, newPass, token).then((x) => {
                if (x.ErrorMessage) {
                    dispatch(setCallStatus('Fail'));
                    dispatch(setErrorMsg(x.ErrorMessage))
                }
                else {
                    dispatch(setCallStatus('Pass'));
                }
                setTimeout(() => {
                    dispatch(setCallStatus(''));
                    dispatch(setErrorMsg(''));
                    if (!x.ErrorMessage) handleClose();
                }, 4500)
                dispatch(setLoading(false));

            })

        }

    }


    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{ sx: { borderRadius: "8px", width: "512px", maxWidth: "none", } }}
            >


                <Grid item container padding={2.5} direction='column' rowGap={3} >
                    <Grid item container className='black' fontWeight={400} alignItems='center' justifyContent='space-between'>
                    <Message id="change.change-password" className='Field-input font-weight-400 font-14' />
                        <span style={{ cursor: 'pointer' }} onClick={() => handleClose()}><CloseIcon /></span>
                        <Grid item className='greyLine' width='100%' xs={12}></Grid>

                    </Grid>

                    {token ?
                        <Grid item container direction='column' xs={12}>
                            <Grid item>
                                Email
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    value={email}
                                    error={emailError}
                                    helperText={emailError ? emailErrorMsg : ''}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        if (!e.target.value || !e.target.value.match(
                                            /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                                        )) {
                                            setEmailError(true);
                                            setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
                                        }
                                        else {
                                            setEmailError(false);
                                            setEmailErrorMsg('')
                                        }

                                    }}
                                    sx={{
                                        '& label.Mui-focused': {
                                            color: '#C3C2C7',
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: '#B2BAC2',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#C3C2C7',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#B2BAC2',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#6F7E8C',
                                            },
                                        },
                                    }}
                                    InputProps={{

                                        className: 'fieldDesign',
                                    }}
                                />

                            </Grid>
                        </Grid>
                        :
                        <Grid item container direction='column' xs={12}>
                            <Grid item>
                            <Message id="change.current-password" className='Field-input font-weight-400 font-14' />
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    value={currentPass}
                                    onChange={(e) => {
                                        setCurrentPass(e.target.value)
                                    }}
                                    sx={{
                                        '& label.Mui-focused': {
                                            color: '#C3C2C7',
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: '#B2BAC2',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#C3C2C7',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#B2BAC2',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#6F7E8C',
                                            },
                                        },
                                    }}
                                    type={passwordVisible ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={() => setPasswordVisible(!passwordVisible)}
                                                edge="end"
                                                sx={{ paddingRight: 1 }}
                                            >
                                                {passwordVisible ? <VisibilityOff htmlColor={currentPass ? '#302E3E' : "#C3C2C7"}/> : <Visibility htmlColor={currentPass ? '#302E3E' : "#C3C2C7"}/>}
                                            </IconButton>
                                        ),
                                        className: 'fieldDesign',
                                    }}
                                />

                            </Grid>
                        </Grid>
                    }


                    <Grid item container direction='column' xs={12}>
                        <Grid item>
                        <Message id="change.new-password" className='Field-input font-weight-400 font-14' />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                value={newPass}
                                onChange={(e) => {
                                    setNewPass(e.target.value);
                                    if (e.target.value !== confirmPass) setPassMismatch(true)
                                    else setPassMismatch(false)
                                }}
                                sx={{
                                    '& label.Mui-focused': {
                                        color: '#C3C2C7',
                                    },
                                    '& .MuiInput-underline:after': {
                                        borderBottomColor: '#B2BAC2',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#C3C2C7',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#B2BAC2',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#6F7E8C',
                                        },
                                    },
                                }}
                                type={newPasswordVisible ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                                            edge="end"
                                            sx={{ paddingRight: 1 }}
                                        >
                                            {newPasswordVisible ? <VisibilityOff htmlColor={newPass ? '#302E3E' : "#C3C2C7"}/> : <Visibility htmlColor={newPass ? '#302E3E' : "#C3C2C7"}/>}
                                        </IconButton>
                                    ),
                                    className: 'fieldDesign',
                                }}
                            />

                        </Grid>
                    </Grid>

                    <Grid item container direction='column' xs={12}>
                        <Grid item>
                        <Message id="change.reenter-new-password" className='Field-input font-weight-400 font-14' />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                value={confirmPass}
                                error={passMismatch}
                                helperText={passMismatch ? intl.formatMessage({ id: 'error.passwords-do-not-match' }) : ""}
                                onChange={(e) => {
                                    setConfirmPass(e.target.value);
                                    if (newPass !== e.target.value) setPassMismatch(true)
                                    else setPassMismatch(false)
                                }}
                                sx={{
                                    '& label.Mui-focused': {
                                        color: '#C3C2C7',
                                    },
                                    '& .MuiInput-underline:after': {
                                        borderBottomColor: '#B2BAC2',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#C3C2C7',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#B2BAC2',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#6F7E8C',
                                        },
                                    },
                                }}
                                type={confirmPasswordVisible ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                            edge="end"
                                            sx={{ paddingRight: 1 }}
                                        >
                                            {confirmPasswordVisible ? <VisibilityOff htmlColor={confirmPass ? '#302E3E' : "#C3C2C7"}/> : <Visibility htmlColor={confirmPass ? '#302E3E' : "#C3C2C7"}/>}
                                        </IconButton>
                                    ),
                                    className: 'fieldDesign',
                                }}
                            />

                        </Grid>
                    </Grid>

                    {token ?
                        <></> :
                        <Grid item style={{ textDecoration: 'underline' }} className='iris-100 pointerText' width='138px'
                            onClick={() => {
                                setOpenForgotPass(true);
                                handleClose();
                            }}
                        >
                          <Message id="login.forgot-password" className='Field-input font-weight-400 font-14' />
                            </Grid>
                    }



                    <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1}>
                        <Grid item xs={3}
                            onClick={() => {
                                handleClose()
                            }}>
                            <button className='greyButton'><Message id="button.cancel" className='labels-extra-bold Field-input labelsLinks' /></button>
                        </Grid>
                        <Grid item xs={3}>
                            <button
                                className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton'}
                                onClick={() => {
                                    if (token) resetPassCall()
                                    else changePassCall()
                                }
                                }
                                disabled={(!currentPass && !token) || !newPass || !confirmPass || passMismatch}
                            >
                                {loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                    : callStatus === 'Pass' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                        : callStatus === 'Fail' ? "Failed"
                                            : <Message id="button.apply" className='labels-extra-bold Field-input labelsLinks' />}
                            </button>
                            <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
                        </Grid>
                    </Grid>



                </Grid>
            </Dialog>

            <ForgotPass
                open={openForgotPass}
                setOpen={setOpenForgotPass}
            />

        </>
    )
}

export default ChangePass
import axios from 'axios';
import * as apiConst from "../taxmation-consts";

const USER_NOTIFICATIONS = apiConst.USER_NOTIFICATIONS;
export const userNotifications = (notificationsIds: any) => {
    return axios
        .post(`${process.env.REACT_APP_URL + USER_NOTIFICATIONS}`, notificationsIds, 
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}



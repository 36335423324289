import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridColType } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import filterIcon from '../../../assets/images/icons/filterIcon.png';
import activeFilterIcon from '../../../assets/images/icons/activeFilterIcon.png';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CheckedIcon from '../../../assets/images/icons/CheckedIcon.png';
import UnCheckedIcon from '../../../assets/images/icons/UncheckedIcon.png';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Grid, TextField } from '@mui/material';
import classes from "../../../assets/styles/Table.module.css";
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UpdateRole from './Dialogs/UpdateRole';
import * as userServices from "../../../services/user-services.proxy";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getLoadingState, setLoading } from '../../../app/slices/loadingSlice';
import { getCallStatus, getErrorMsg, setCallStatus, setErrorMsg } from '../../../app/slices/apiCallSlice';
import DeleteDialog from '../../../sharedComponents/Dialogs/DeleteDialog';
import * as statusesConsts from "../../../status-consts";
import selectedFilter from "../../../assets/images/icons/selectedFilter.png";
import accessIcon from "../../../assets/images/icons/accessIcon.png";
import deleteIcon from "../../../assets/images/icons/delete.png";
import * as userRolesConst from "../../../user-roles-consts";
import { selectRoles, selectUserType } from '../../../app/slices/userDataSlice';
import { useIntl } from "react-intl";
import Message from '../../../sharedComponents/ui/Message';
import ConfigureAccess from './Dialogs/ConfigureAccess';
import moment from "moment";

const updateTMRole = userServices.updateTMRole;
const deleteUser = userServices.deleteUser;
const getUserModule = userServices.getUserModule;
const getUserData = userServices.getUserData


export interface TableProps {
  rows: any;
  dataLength: any;
  setDataLength: any;
  roles: any;
  selectedRoles: any,
  setSelectedRoles: any,
  modify: any,
  setModify: any,
  paginationModel: any,
  setPaginationModel: any,
  // pageNb: any,
  // setPageNb: any,
  // pageSize: any,
  // setPageSize: any,
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '0px 2px 4px 0px #0000001F',
    backgroundColor: '#FEFEFF',
    borderRadius: '10px',
    padding: 0,
  },
}));

const TeamMembersTable = (props: TableProps) => {

  const { rows, roles, selectedRoles, setSelectedRoles, modify, setModify, dataLength, paginationModel, setPaginationModel } = props;

  const dispatch = useAppDispatch();
  const intl = useIntl();

  const loading = useAppSelector(getLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);
  const callStatus = useAppSelector(getCallStatus);
  const userRoles = useAppSelector(selectRoles);
  const userType = useAppSelector(selectUserType);

  const [filterIndex, setFilterIndex] = useState<number>();
  const [search, setSearch] = useState<string>();

  //update role 
  const [openUpdateRoleDialog, setOpenUpdateRoleDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [selectedUser, setSelectedUser] = useState<any>();
  const [selectedUserToDelete, setSelectedUserToDelete] = useState<any>();

  const [isOpenRoles, setIsOpenRoles]= useState(false);
  const [clickedRoles, setClickedRoles]= useState<number[]>([]);

  const TMRoles = roles?.filter((r: { typeId: number; }) => (localStorage.getItem('userType') === '1' ? r.typeId === 1 : r.typeId === 2));
  const [filteredRoles, setFilteredRoles] = useState<any>(TMRoles);
  const extractRolesIds = (rolesString: string) => {

    const roleNames = rolesString.split(',').map(name => name.trim());

    const ids = roles
      .filter((role: { name: string; }) => roleNames?.includes(role.name))
      .map((role: { id: any; }) => role.id);

    return ids;
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);

    const filtered = TMRoles?.filter((role: any) =>
      role.name.toLowerCase()?.includes(searchValue)
    );
    setFilteredRoles(filtered);
  };

  const [openConfigureAccessDialog, setOpenConfigureAccessDialog] = useState(false);

  const [taxAdvisorClients, setTaxAdvisorClients] = useState([]);
  const [modules, setModules] = useState([]);
  const [selectedTM, setSelectedTM] = useState<any>();
  const [userDataLoading, setUserDataLoading] = useState(false);

  useEffect(() => {
    getUserData().then((x) => {
      let tmpClients = x.clients.map((client: any) => ({
        ...client,
        expand: true
      }));
      setTaxAdvisorClients(tmpClients);
      // setTaxAdvisorClients(x.clients);
      setModules(x.modules)
    })
  }, [])

  useEffect(() => {
    if (isOpenRoles) {
      setFilteredRoles(TMRoles);
      setSearch('');
    }
  }, [isOpenRoles]);

  const getUserDataCall = (userId: string) => {
    setUserDataLoading(true);
    getUserModule(userId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 4500);
      } else {
        dispatch(setCallStatus("Pass"));
        setSelectedTM(x);
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          setOpenDeleteDialog(false);
          dispatch(setModify(!modify));
        }, 1000);
      }
      setUserDataLoading(false);
    });
  };

  const columns: GridColDef[] = [
    ...((userType === 1 && userRoles?.includes(userRolesConst.ADMIN_ID)) || (userType === 2 && userRoles?.includes(userRolesConst.CLIENT_ADMIN_ID)) ? [{
      field: 'actions',
      type: 'actions' as GridColType,
      width: 10,
      sortable: false,
      renderCell: (params: any) => (
        <HtmlTooltip
          placement='bottom-start'
          title={
            <React.Fragment>
              <Grid item container rowGap={1.5} padding={1.5} maxWidth='260px' className="font-14">
                {/* {userRoles.includes(userRolesConst.SUPER_ADMIN_ID || userRolesConst.ADMIN_ID) ? */}
                <Grid item container direction='row' className="black  Field-input labelsLinks font-weight-400 font-14" alignItems='center' columnGap={1}
                  onClick={() => {
                    setSelectedUser(() => ({
                      userId: params.row.id,
                      email: params.row.email,
                      roles: extractRolesIds(params.row.roles),
                      type: 1,
                    }));
                    setOpenUpdateRoleDialog(true);
                  }}

                >
                  <EditOutlinedIcon htmlColor='black' fontSize='small' /><Message id="ta.edit.edit-roles" className="Field-input labelsLinks font-weight-400" />
                </Grid>

                <Grid item container direction='row' className="black  Field-input labelsLinks font-weight-400 font-14" alignItems='center' columnGap={1}
                  onClick={() => {
                    setSelectedTM(params.row);
                    setOpenConfigureAccessDialog(true)
                    getUserDataCall(params.row.id);
                  }}

                >
                  <img src={accessIcon} /> <Message id="text.edit-company-module" className="Field-input labelsLinks font-weight-400" />
                </Grid>
                {/* :
                  <></>} */}


                {/* {userRoles.includes(userRolesConst.SUPER_ADMIN_ID) ? */}
                <Grid item container direction='row' className='red labels pointerText' alignItems='center' columnGap={1}
                  onClick={() => {
                    setSelectedUserToDelete(() => ({
                      userId: params.row.id,
                      email: params.row.email,
                      roles: params.row.roles,
                      taskCount: params.row.taskCount
                    }));
                    setOpenDeleteDialog(true);
                  }}
                >
                  {/* <DeleteOutlineIcon htmlColor='#EF4737' fontSize='small' /> */}
                  <img src={deleteIcon} width='20px' height='20px' />

                  <Message id="ta.delete" className="Field-input labelsLinks font-weight-400" /></Grid>
                {/* :
                  <></>
                } */}

              </Grid>
            </React.Fragment>
          }
        >
          <Grid item style={{ cursor: 'pointer' }}><MoreVertIcon htmlColor='#93919a' /></Grid>
        </HtmlTooltip>
      ),
    }] : []),
    { field: 'firstName', headerName: intl.formatMessage({ id: 'user.profile.first-name' }), width: 130 },
    { field: 'lastName', headerName: intl.formatMessage({ id: 'user.profile.last-name' }), width: 130 },
    { field: 'email', headerName: intl.formatMessage({ id: 'user.profile.email' }), width: 270 },
    {
      field: 'roles', headerName: intl.formatMessage({ id: 'user.user-role' }), width: 190,sortable:false,
      renderHeader(params: any) {
        return (
          <ClickAwayListener  onClickAway={(event) => {
            const target = event.target as HTMLElement;
            if (
                isOpenRoles &&
                !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip') 
            ) {
                setIsOpenRoles(false);
            }
        }}>
          <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={()=> setIsOpenRoles((prev)=>!prev)}>
            <Message id="user.user-role" className="Field-input labels font-weight-400" />
            <HtmlTooltip
            open={isOpenRoles}
            onClose={()=>setIsOpenRoles(false)}
            disableHoverListener
            disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                    <Grid item>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom:'10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={handleSearch}
                      /></Grid>
                    <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
                    className='font-14 black normalFontWeight' padding={1}
                    >
                    {filteredRoles?.map((val: any, index: number) => (
                      <Grid item container alignItems='center' key={val.id} columnGap={1} 
                      // className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                       justifyContent='flex-start'
                       style={{
                        flexWrap: 'nowrap',
                        width: '100%',
                        fontSize:'14px',
                        marginBottom:'18px'}}
                        onMouseOver={(e) => {
                          const target = e.target as HTMLElement;
                          if (!clickedRoles?.includes(val.id)) {
                            target.style.cursor = 'pointer';
                            setFilterIndex(index);
                          }
                        }}
                        onMouseOut={() => {
                          setFilterIndex(-1);
                        }}
                        onClick={(e) => {
                          // if (!clickedRoles?.includes(val.id)) {
                          //   setClickedRoles((prevTenants: any) => [...prevTenants, val.id]);
                          // }
                          setClickedRoles((prevSelectedRoles:any)=>{
                            if (prevSelectedRoles.includes(val.id)) {
                              return prevSelectedRoles.filter((id: any) => id !== val.id);
                            }
                            else {
                              return [...prevSelectedRoles, val.id];
                            }
                          })
                        }}
                      >
                           <Grid className='bordered-box'
                                  style={{ borderColor: clickedRoles?.includes(val.id)  ? '#fff' : '#E6E6E8' }}
                                  bgcolor={clickedRoles?.includes(val.id) ? '#CCF0EB' : '#fff'}>
                                  {
                                    clickedRoles?.includes(val.id)  ?
                                      // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                      <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                      :
                                    <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  }
                                </Grid>
                        {/* <Grid className='bordered-box' bgcolor={filterIndex === index || selectedRoles?.includes(val.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || selectedRoles?.includes(val.id) ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                        <Grid item>{val.name}</Grid>
                      </Grid>
                    ))}
                    
                    </Grid>
                    <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedRoles.length > 0 ? 'pointer' : 'unset' }}
                    onClick={() => setClickedRoles([])} ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                    </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                    <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation();
                       setClickedRoles([]);
                       setIsOpenRoles(false);
                    }}>
                    <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation();
                      setSelectedRoles(clickedRoles)
                      setIsOpenRoles(false);
                    }}>
                    <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    
                  </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              {/* <ArrowDropDownIcon
              style={{
                transform: isOpenRoles ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease', 
              }}
            />  */}
          <img src={selectedRoles.length>0 ? activeFilterIcon :filterIcon} style={{marginLeft:'5px', cursor:'pointer',borderRadius:'2px'}}/>
              </Grid>
            </HtmlTooltip>
          </Grid >
          </ClickAwayListener>
        )
      },
    },
    { field: 'lastActiveDate', headerName: intl.formatMessage({ id: 'user.last-active' }), width: 200 },
    {
      field: 'statusId', headerName: intl.formatMessage({ id: 'user.status' }), width: 170,
      renderCell: (params) => {
        return (
          <Grid item container direction='row' style={{ marginTop: '10px', height: '15px' }} columnGap={1.5}>
            <Grid item className={params.value === statusesConsts.ACTIVE_STATUS_ID ? 'active-status' :
              params.value === statusesConsts.IN_ACTIVE_STATUS_ID ? 'not-active-status' : params.value === statusesConsts.INVITED_STATUS_ID ? 'invited-status' : ''} width='70px'>
              {params.value === statusesConsts.ACTIVE_STATUS_ID ? 'Active' :
                params.value === statusesConsts.IN_ACTIVE_STATUS_ID ? 'In Active' : params.value === statusesConsts.INVITED_STATUS_ID ? 'Invited' : ''}
            </Grid>
          </Grid>
        );
      },
    },
    { field: 'phoneNumber', headerName: intl.formatMessage({ id: 'user.profile.phone-number' }), width: 200 },
    { field: 'primaryContactPerson', headerName: intl.formatMessage({ id: 'user.primary-contact-person' }), width: 200 },
    { field: 'country', headerName: intl.formatMessage({ id: 'user.country' }), width: 200 },
    // { field: 'position', headerName: 'Position', width: 200 },
    {
      field: 'creationDate', headerName: intl.formatMessage({ id: 'user.creation-date' }), width: 200,
      renderCell: (params) => {
        return (
          <span>{moment(params.row.creationDate).format('DD.MM.YYYY')}</span>
        )
      }
    },
  ];

  const updateUserRoleCall = () => {
    dispatch(setLoading(true));
    updateTMRole({ userId: selectedUser.userId, roles: selectedUser.roles }).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''))
        }, 4500)
      }
      else {
        dispatch(setCallStatus('Pass'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''));
          setOpenUpdateRoleDialog(false);
          dispatch(setModify(!modify))
        }, 1000)
      }
      dispatch(setLoading(false));
    })
  }

  const deleteUserCall = () => {
    dispatch(setLoading(true));
    deleteUser(selectedUserToDelete.userId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''))
        }, 4500)
      }
      else {
        dispatch(setCallStatus('Pass'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''));
          setOpenDeleteDialog(false);
          dispatch(setModify(!modify))
        }, 1000)
      }
      dispatch(setLoading(false));
    })
  }

  const handlePaginationModelChange = (model: any) => {
    // const adjustedPage = Math.max(1, model.page);
    // // Catch the new page number
    // setPageNb(adjustedPage);
    // setPageSize(model.pageSize);
    // Update the pagination model
    setPaginationModel(model);
  };

  return (
    <div style={{ height: '53vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        paginationMode='server'
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange} // Handle pagination changes
        rowCount={dataLength} // Total number of rows for server-side pagination
        pageSizeOptions={[25, 50, 100]}
        // checkboxSelection
        disableColumnMenu
        sx={{
          border: 2,
          borderColor: '#FEFEFF',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
        }}
      />
      {selectedUser ?
        <UpdateRole
          openUpdateRoleDialog={openUpdateRoleDialog}
          setOpenUpdateRoleDialog={setOpenUpdateRoleDialog}
          user={selectedUser}
          setUser={setSelectedUser}
          roles={roles}
          confirmAction={updateUserRoleCall}
          loading={loading}
          callStatus={callStatus}
          errorMsg={errorMsg}
        />
        :
        <></>
      }

      {selectedUserToDelete ?
        <DeleteDialog
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          loading={loading}
          callStatus={callStatus}
          errorMsg={errorMsg}
          object={selectedUserToDelete}
          confirmAction={deleteUserCall}
          type='team member'
        />
        :
        <></>
      }
      <ConfigureAccess
        openConfigureAccessDialog={openConfigureAccessDialog}
        setOpenConfigureAccessDialog={setOpenConfigureAccessDialog}
        user={selectedTM}
        setUser={setSelectedTM}
        modules={modules}
        taxAdvisorClients={taxAdvisorClients}
        setTaxAdvisorClients={setTaxAdvisorClients}
        type='Update TM'
        userDataLoading={userDataLoading}
      />

    </div>
  )
}

export default TeamMembersTable
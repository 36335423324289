import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridColType } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import filterIcon from '../../../assets/images/icons/filterIcon.png';
import activeFilterIcon from '../../../assets/images/icons/activeFilterIcon.png';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CheckedIcon from '../../../assets/images/icons/CheckedIcon.png';
import UnCheckedIcon from '../../../assets/images/icons/UncheckedIcon.png';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Backdrop, Grid, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getLoadingState, setLoading } from '../../../app/slices/loadingSlice';
import { getActionCallFrom, getCallStatus, getErrorMsg, setActionCallFrom, setCallStatus, setErrorMsg } from '../../../app/slices/apiCallSlice';
import DeleteDialog from '../../../sharedComponents/Dialogs/DeleteDialog';
import * as statusesConsts from "../../../status-consts";
import selectedFilter from "../../../assets/images/icons/selectedFilter.png";
import * as userRolesConst from "../../../user-roles-consts";
import { selectRoles, selectUserType } from '../../../app/slices/userDataSlice';
import { useIntl } from "react-intl";
import Message from '../../../sharedComponents/ui/Message';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import orangetask from '../../../assets/images/icons/orangetask.png';
import redtask from '../../../assets/images/icons/redtask.png';
import greentask from '../../../asset/images/icons/greentask.png';
import { useNavigate } from 'react-router-dom';
import classes from "../../../assets/styles/Task.module.css";
import * as TaskServices from '../../../services/task-services.proxy';
import * as StatusesConst from "../../../status-consts";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'moment/locale/en-gb';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import deleteIcon from "../../../assets/images/icons/delete.png";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import * as StatusesConsts from "../../../status-consts";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as taskConsts from "../../../task-consts";
import fileNotificationIcon from "../../../assets/images/icons/fileNotification.png";
import messageNotificationIcon from "../../../assets/images/icons/messageNotification.png";

import CreateTask from './CreateTask';

const updateTask = TaskServices.updateTask;
const deleteTask = TaskServices.deleteTask
const duplicateTask = TaskServices.duplicateTask;


export interface TableProps {
  rows: any;
  dataLength: any;
  setDataLength: any;
  modify: any,
  setModify: any,
  statusesList: any,
  typesList: any,
  moduleList: any,
  assigneesList: any,
  selectedStatus: any,
  setSelectedStatus: any,
  selectedClient: any,
  setSelectedClient: any,
  selectedAssignee: any,
  setSelectedAssignee: any,
  selectedType: any,
  setSelectedType: any,
  selectedModule: any,
  setSelectedModule: any,
  selectedPeriod: any,
  setSelectedPeriod: any,
  dueDate: any,
  setDueDate: any,
  clientsList: any,
  paginationModel: any,
  setPaginationModel: any,
  // pageNb: any,
  // setPageNb: any,
  // pageSize: any,
  // setPageSize: any,
  recurringPeriodsList: any;
  internalAssigneesList: any;
  externalAssigneesList: any;
  setExternalAssigneesList: any;
  open: any;
  setOpen: any;
  taskObject: any;
  setTaskObject: any;
  updateTaskStatusCall: any;
  remindersList: any;
  postReminderCall: any;
  inActiveReminderCall: any;
  setOpenCustomReminder: any;
  openCustomReminder: any;
  tableSearchLoading: any;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '0px 2px 4px 0px #0000001F',
    backgroundColor: '#FEFEFF',
    borderRadius: '10px',
    padding: 0,
  },
}));


const TasksTable = (props: TableProps) => {


  const {
    rows,
    modify,
    setModify,
    dataLength,
    setDataLength,
    statusesList,
    selectedStatus,
    setSelectedStatus,
    selectedClient,
    setSelectedClient,
    selectedAssignee,
    setSelectedAssignee,
    typesList,
    moduleList,
    selectedType,
    setSelectedType,
    selectedModule,
    setSelectedModule,
    selectedPeriod,
    setSelectedPeriod,
    assigneesList,
    clientsList,
    paginationModel,
    setPaginationModel,
    recurringPeriodsList,
    internalAssigneesList,
    externalAssigneesList,
    setExternalAssigneesList,
    dueDate,
    setDueDate,
    open,
    setOpen,
    taskObject,
    setTaskObject,
    updateTaskStatusCall,
    remindersList,
    postReminderCall,
    inActiveReminderCall,
    openCustomReminder,
    setOpenCustomReminder,
    tableSearchLoading } = props;

  const dispatch = useAppDispatch();
  const intl = useIntl();
  const navigate = useNavigate();

  const loading = useAppSelector(getLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);
  const callStatus = useAppSelector(getCallStatus);
  const userRoles = useAppSelector(selectRoles);
  const userType = useAppSelector(selectUserType);
  const actionCallFrom = useAppSelector(getActionCallFrom);

  //update role 
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [selectedTask, setSelectedTask] = useState<any>();
  const [selectedTaskToDelete, setselectedTaskToDelete] = useState<any>();
  const [selectedTaskToDuplicate, setSelectedTaskToDuplicate] = useState<any>();

  const [filterIndex, setFilterIndex] = useState<number>(0);
  const [clientIndex, setClientIndex] = useState<number>(-1);
  const [typeIndex, setTypeIndex] = useState<number>(-1);
  const [moduleIndex, setModuleIndex] = useState<number>(-1);
  const [assigneeIndex, setAssigneeIndex] = useState<number>(-1);
  const [recurringPeriodIndex, setRecurringPeriodIndex] = useState(-1);

  const [selectedDate, setSelectedDate] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [openAssigneesDropdown, setOpenAssigneesDropdown] = useState(false);
  const [assigneesType, setAssigneesType] = useState('Internal');
  // const [taskObject, setTaskObject] = useState({assigneeId:''});

const [clickedStatus, setClickedStatus]= useState<number[]>([]);
const [clickedType, setClickedType]= useState<number>();
const [clickedClient, setClickedClient]=useState<string>('');
const [clickedPeriod, setClickedPeriod]= useState();
const [clickedModule, setClickedModule]=useState<number>();
const [clickedAssignee, setClickedAssignee]=useState<string>();
const [isOpenPeriod, setIsOpenPeriod]= useState(false);
const [isOpenType, setIsOpenType]= useState(false);
const [isOpenStatus, setIsOpenStatus]= useState(false);
const [isOpenClient, setIsOpenClient]= useState(false);
const [isOpenModule, setIsOpenModule]= useState(false);
const [clientSearch ,setClientSearch]= useState('');
const [filteredClientsList, setFilteredClientsList] = useState(clientsList);
const [internalAssigneeSearch, setInternalAssigneeSearch]= useState('');
const [externalAssigneeSearch, setExternalAssigneeSearch]= useState('');
const [filteredExternalAssigneesList, setFilteredExternalAssigneesList]= useState(externalAssigneesList);
const [filteredInternalAssigneesList, setFilteredInternalAssigneesList]= useState(internalAssigneesList);

  //reminders
  const [taskId, setTaskId] = useState(null);
  const [reminderTypeId, setReminderTypeId] = useState(0);
  const [reminderDate, setReminderDate] = useState(null);
  const [selectedRowReminders, setSelectedRowReminders] = useState<any>([]);
  const reminderObj = {
    taskId: taskId,
    date: reminderDate,
    typeId: reminderTypeId
  }

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => {
    // Updating state with an empty object to trigger a re-render
    updateState({});
  }, []);

  const handleCloseCustomReminder = () => {
    setOpenCustomReminder(false);
  };

  const handleReminderDateChange = (newValue: any) => {
    console.log("checkk")
    if (!newValue) {
      setReminderDate(null)
      return;
    }
    const formattedDate = newValue.format('YYYY-MM-DD');
    setReminderDate(formattedDate);
    forceUpdate();
  }

  const handleDateChange = (newValue: any) => {
    setTooltipOpen(false);
    if (!newValue) {
      setDueDate(null);
      return;
    }
    const formattedDate = newValue.format('YYYY-MM-DD');
    setDueDate(formattedDate);
  }

  useEffect(() => {
    if (isOpenClient) {
      setFilteredClientsList(clientsList);
      setClientSearch('');
    }
  }, [isOpenClient, clientsList]);

  useEffect(() => {
    if(assigneesType==="External"){
if(openAssigneesDropdown){
  setFilteredExternalAssigneesList(externalAssigneesList);
  setExternalAssigneeSearch('');
}
    }
    else{
      if(openAssigneesDropdown){
        setFilteredInternalAssigneesList(internalAssigneesList);
        setInternalAssigneeSearch('');
    }
  }
},[openAssigneesDropdown, externalAssigneesList, internalAssigneesList]);

  const columns: GridColDef[] = [
    ...((userType === 1 && (userRoles?.includes(userRolesConst.ADMIN_ID) || userRoles?.includes(userRolesConst.TAX_ADVISOR_ID) || userRoles?.includes(userRolesConst.CLERK_ID))) || userType === 2 ? [{
      field: 'actions',
      type: 'actions' as GridColType,
      width: 10,
      sortable: false,
      renderCell: (params: any) => (
        <HtmlTooltip
          title={
            <React.Fragment>
              <Grid item container rowGap={1.5} padding={1.5} maxWidth='180px'>
                <Grid item container direction='row' className='neutral-4 labels pointerText' alignItems='center' columnGap={1}
                  onClick={() => {
                    navigate(`/task-details?id=${params.row.id}`)
                    setSelectedTask(() => ({
                      userId: params.row.id,
                    }));
                  }}
                >
                  <EditOutlinedIcon htmlColor='black' fontSize='small' /><Message id="task.edit" className="black Field-input labelsLinks font-weight-400 font-14" />
                </Grid>

                <Grid item container direction='row' className='black labels pointerText normalFontWeight' alignItems='center' columnGap={1}
                  onClick={() => {

                  }}
                >
                  <NotificationsActiveOutlinedIcon htmlColor='black' fontSize='small' /><Message id="task.set-reminder" className="black Field-input labelsLinks font-weight-400 font-14" />
                  <HtmlTooltip
                    placement='right-start'
                    title={
                      <React.Fragment>
                        <Grid item container rowGap={1.5} padding={2} maxWidth='270px' className='font-14 font-weight-400 black'>
                          {remindersList?.map((r: any) => (
                            <>
                              <Grid item key={r.id}
                                className={params.row.reminders.length > 0 && params.row.reminders[0].typeId === r.id ? 'bold pointerUnset' : 'normalFontWeight pointerText'}
                                onClick={() => {
                                  let tmp = {
                                    taskId: params.row.id,
                                    date: null,
                                    typeId: r.id
                                  }
                                  setTaskId(params.row.id);
                                  setReminderTypeId(r.id)

                                  if (params.row.reminders.length === 0) {
                                    postReminderCall(tmp, null)
                                  }
                                  else postReminderCall(tmp, params.row.reminders[0].id)

                                }}>
                                {r.name === 'Tomorrow' ? <Message id="task.remind-tomorrow" className={params.row.reminders.length > 0 && params.row.reminders[0].typeId === r.id ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} /> :
                                  r.name === 'InTwoDays' ? <Message id="task.remind-in-2-days" className={params.row.reminders.length > 0 && params.row.reminders[0].typeId === r.id ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} /> :
                                    r.name === 'TwoDaysBeforeDue' ? <Message id="task.remind-before-duedate" className={params.row.reminders.length > 0 && params.row.reminders[0].typeId === r.id ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} /> :
                                      ""}

                              </Grid>
                              {r.name === 'Custom' ?
                                <Grid item
                                  className={params.row.reminders.length > 0 && params.row.reminders[0].typeId === r.id ? 'bold pointerText' : 'normalFontWeight pointerText'}
                                  onClick={() => {
                                    if (params.row.reminders.length > 0 && params.row.reminders[0].typeId) setReminderDate(params.row.reminders[0].date)
                                    // if (params.row.reminders.length === 0) {
                                    setTaskId(params.row.id);
                                    setReminderTypeId(r.id)
                                    setOpenCustomReminder(true);
                                    setSelectedRowReminders(params.row.reminders);
                                    // }
                                  }
                                  }
                                >
                                  {params.row.reminders.length > 0 && params.row.reminders[0].typeId === r.id ?
                                    <Message id="task.change-custom.reminder" className={params.row.reminders.length > 0 && params.row.reminders[0].typeId === r.id ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} /> :  <Message id="task.custom-reminder" className={params.row.reminders.length > 0 && params.row.reminders[0].typeId === r.id ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} />}

                                </Grid> : ""}
                            </>

                          ))}
                          {/* <Grid item>Remind me tomorrow</Grid>
                          <Grid item>Remind me in 2 days</Grid>
                          <Grid item>Remind me 2 days before Due date</Grid>
                          <Grid item
                            onClick={() => setOpenCustomReminder(true)}
                          > Set Custom Reminder
                          </Grid> */}
                          <Grid item
                            className={params.row.reminders.length > 0 ? 'pointerText' : 'pointerUnset'}
                            onClick={() => {
                              if (params.row.reminders.length > 0) {
                                inActiveReminderCall(params.row.reminders[0].id)
                              }
                            }
                            }
                          >
                             <Message id="task.remove-reminder" className={params.row.reminders.length > 0 ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} /></Grid>
                        </Grid>
                      </React.Fragment>
                    }>
                    <ArrowRightOutlinedIcon htmlColor='#93919a' />
                  </HtmlTooltip>
                </Grid>

                <Grid item container direction='row' className="black Field-input labelsLinks font-weight-400 font-14" alignItems='center' columnGap={1}
                  onClick={() => {
                    setSelectedTaskToDuplicate(() => ({
                      taskId: params.row.id
                    }))

                    setTaskObject(() => ({
                      title: `${params.row.title} (copy)`,
                      status: 1,
                      description: params.row.description,
                      note: params.row.note,
                      recurringPeriod: params.row.recurringPeriod,
                      priority: params.row.priority,
                      dueDate: params.row.dueDate ? moment(params.row.dueDate).format('YYYY-MM-DD') : null,
                      type: params.row.type,
                      clientId: params.row.clientId,
                      assigneeId: params.row.assigneeId,
                      module: params.row.module
                    }))
                    setOpen(true);
                  }}
                >
                  <ContentCopyOutlinedIcon htmlColor='black' fontSize='small' width='20px' height='20px' /> <Message id="task.duplicate" className="black Field-input labelsLinks font-weight-400 font-14" />
                </Grid>

                <Grid item container direction='row' className="black Field-input labelsLinks font-weight-400 font-14" alignItems='center' columnGap={1}
                  onClick={() => {
                    if (params.row.status !== StatusesConsts.CLOSED_STATUS_ID) updateTaskStatusCall(params.row.id, StatusesConsts.CLOSED_STATUS_ID)
                    if (params.row.status === StatusesConsts.CLOSED_STATUS_ID) updateTaskStatusCall(params.row.id, StatusesConsts.OPEN_STATUS_ID)
                  }}
                >
                  <CheckIcon htmlColor='black' fontSize='small' />
                  {params.row.status === statusesConsts.CLOSED_STATUS_ID ? <Message id="task.open-task" className="black Field-input labelsLinks font-weight-400 font-14" /> : <Message id="task.complete-task" className="black Field-input labelsLinks font-weight-400 font-14" />}
                </Grid>

                {userRoles?.includes(userRolesConst.ADMIN_ID)
                  || userRoles?.includes(userRolesConst.TAX_ADVISOR_ID)
                  || userRoles?.includes(userRolesConst.CLIENT_ADMIN_ID) ?
                  <Grid item container direction='row' className='red labels pointerText' alignItems='center' columnGap={1}
                    onClick={() => {
                      setselectedTaskToDelete(() => ({
                        id: params.row.id,
                        title: params.row.title
                      }));
                      setOpenDeleteDialog(true);
                    }}
                  >
                    <img src={deleteIcon} width='24px' height='24px' />
                    <Message id="ta.delete" className="red Field-input labelsLinks font-weight-400 font-14" />

                    {/* <DeleteOutlineIcon htmlColor='#EF4737' fontSize='small' /><*/}
                  </Grid>
                  :
                  <></>
                }

              </Grid>
            </React.Fragment>
          }
        >
          <Grid item style={{ cursor: 'pointer' }}><MoreVertIcon htmlColor='#93919a' /></Grid>
        </HtmlTooltip>
      ),
    }] : []),

    {
      field: 'title', headerName: intl.formatMessage({ id: 'task.task' }), width: 410,

      renderCell: (params) => {
        return (
          <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item >
              {params.row.title}
            </Grid>
            {params.row.notifications?.length > 0 ?
              <Grid paddingTop={1}>
                {params.row.notifications?.find((n: any) => n.type === taskConsts.comment_Id) ?
                  <img src={messageNotificationIcon} width='24px' height='24px' alt='icon' style={{paddingRight:'8px'}}/>
                  :

                  <></>
                }
                {params.row.notifications?.find((n: any) => n.type === taskConsts.file_Id) ?
                  <img src={fileNotificationIcon} width='15px' height='24px' alt='icon' />
                  :
                  <></>}
                {/* {params.row.notifications?.map((n: any) => (
                  <> {n.type === taskConsts.comment_Id ?
                    <img src={messageNotificationIcon} width='24px' height='24px' alt='icon' />
                    : n.type === taskConsts.file_Id ?
                      <img src={fileNotificationIcon} width='15px' height='24px' alt='icon' />
                      :
                      ""
                  }</>

                ))} */}

              </Grid>
              :
              <></>}

          </Grid>
        )
      }
    },
    {
      field: 'status',
      headerName: intl.formatMessage({ id: 'user.status' }),
      width: 180,
      sortable: false,
      renderHeader(params) {
        return (
          <ClickAwayListener  onClickAway={(event) => {
            const target = event.target as HTMLElement;
            if (
                isOpenStatus &&
                !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip') 
            ) {
                setIsOpenStatus(false);
            }
        }}>
          <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={() => setIsOpenStatus((prev) => !prev)}>
            <Message id="user.status" className="Field-input labels font-weight-400" />
            <HtmlTooltip
            open={isOpenStatus}
            onClose={() => setIsOpenStatus(false)}
            disableHoverListener
            disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px',boxShadow: '0px 2px 4px 0px #0000001F',padding:'10px',borderRadius:'13px'}}>

                    <Grid container rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }}>
                      {statusesList && statusesList.length > 0 &&
                        statusesList.map((status: any, index: number) => (
                          <Grid
                            key={status.id}
                            item
                            container
                            alignItems='center'
                            columnGap={2}
                            //className={classes[(filterIndex === index || selectedStatus.includes(status.id)) ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent='flex-start'
                            className='black'
                          
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize:'14px',
                              marginBottom:'18px'
                            }}
                            onMouseOver={(e) => {
                              const target = e.target as HTMLElement;
                              if (!clickedStatus.includes(status.id)) {
                                target.style.cursor = 'pointer';
                                setFilterIndex(index);
                              }
                            }}
                            onMouseOut={() => {
                              setFilterIndex(-1);
                            }}
                            onClick={() => {
                              setClickedStatus((prevSelectedStatus: any) => {
                                if (prevSelectedStatus.includes(status.id)) {
                                  return prevSelectedStatus.filter((id: any) => id !== status.id);
                                }
                                else {
                                  return [...prevSelectedStatus, status.id];
                                }
                              });
                            }}
                          >
                            <Grid className='bordered-box'
                              style={{ borderColor: clickedStatus.includes(status.id) ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedStatus.includes(status.id) ? '#CCF0EB' : '#fff'}>
                              {
                                clickedStatus.includes(status.id) ?
                                  // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            <Grid item>{status.name}</Grid>
                           
                            {/* <Grid
                              className='bordered-box'
                              bgcolor={
                                filterIndex === index || selectedStatus.includes(status.id) ? (
                                  status.id === statusesConsts.NEW_STATUS_ID ? '#E3F2FD' :
                                    status.id === statusesConsts.OPEN_STATUS_ID ? '#E8F5E9' :
                                      status.id === statusesConsts.OVERDUE_STATUS_ID ? '#FFEBEE' :
                                        status.id === statusesConsts.IN_PROGRESS_STATUS_ID ? '#FFF3E0' :
                                          status.id === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? '#F3E5F5' :
                                            status.id === statusesConsts.CLOSED_STATUS_ID ? '#EEEEEE' :
                                              status.id === statusesConsts.UPCOMING_STATUS_ID ? '#E1F5FE' :
                                                '#E0E0E0'
                                ) : '#E0E0E0'
                              }
                            >
                              <CheckIcon
                                fontSize='small'
                                htmlColor={
                                  filterIndex === index || selectedStatus.includes(status.id) ? (
                                    status.id === statusesConsts.NEW_STATUS_ID ? '#2196F3' :
                                      status.id === statusesConsts.OPEN_STATUS_ID ? '#4CAF50' :
                                        status.id === statusesConsts.OVERDUE_STATUS_ID ? '#F44336' :
                                          status.id === statusesConsts.IN_PROGRESS_STATUS_ID ? '#FF9800' :
                                            status.id === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? '#9C27B0' :
                                              status.id === statusesConsts.CLOSED_STATUS_ID ? '#616161' :
                                                status.id === statusesConsts.UPCOMING_STATUS_ID ? '#03A9F4' :
                                                  '#9E9E9E'
                                  ) : '#9E9E9E'
                                }
                              />
                            </Grid> */}
                          </Grid>
                        ))}
                      
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems='center'
                      className='black font-weight-400'
                      columnGap={1}
                      style={{ cursor: clickedStatus ? 'pointer' : 'unset' }}
                      marginLeft={0.8}
                      marginTop={0.5}
                      onClick={() => {
                        setClickedStatus([]);
                      }}
                    >
                      <RestartAltIcon fontSize='small' />
                      <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                    </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                    <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation();
                       setClickedStatus([]);
                       setIsOpenStatus(false);
                    }}>
                    <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation();
                      setSelectedStatus(clickedStatus)
                      setIsOpenStatus(false);
                    }}>
                    <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    
                  </Grid>
                    
                  </Grid>
                </React.Fragment>
              }
            >
              {/* <ArrowDropDownIcon
              style={{
                transform: isOpenStatus ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease', 
              }}
            /> */}
             <img src={selectedStatus.length>0 ? activeFilterIcon:filterIcon} style={{marginLeft:'5px', cursor:'pointer',borderRadius:'2px'}}/>
            </HtmlTooltip>
          </Grid>
          </ClickAwayListener>
        );
      },
      renderCell: (params) => {
        return (
          <HtmlTooltip
            placement='bottom'
            title={
              <React.Fragment>
                <Grid item container direction='column' rowGap={1.5} padding={2} maxWidth='270px' className='font-14 font-weight-400 black'>
                  {statusesList?.map((option: any) => (
                    <>
                      {option.id === StatusesConst.IN_PROGRESS_STATUS_ID || option.id === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ?
                        <Grid item
                          key={option.id}
                          style={{
                            color: option.id !== params.row.status ? 'black' : '#93919A',
                            cursor: option.id !== params.row.status ? 'pointer' : 'unset'
                          }}
                          onClick={() => {
                            if (option.id !== params.row.status) updateTaskStatusCall(params.row.id, option.id)
                          }}

                        >
                          {option.id === StatusesConst.IN_PROGRESS_STATUS_ID ? "In Progress" :
                            option.id === StatusesConst.WAITING_FOR_OTHERS_STATUS_ID ? "Waiting For Others" : ""}
                        </Grid>
                        :
                        <></>
                      }

                    </>
                  ))}
                </Grid>
              </React.Fragment>
            }>
            <Grid item container direction='row' columnGap={1.5} marginTop={1}>
              <Grid item
                className={classes[
                  params.row.status === statusesConsts.NEW_STATUS_ID ? 'new-task' :
                    params.row.status === statusesConsts.OPEN_STATUS_ID ? 'open-task' :
                      params.row.status === statusesConsts.OVERDUE_STATUS_ID ? 'overdue-task' :
                        params.row.status === statusesConsts.IN_PROGRESS_STATUS_ID ? 'inprogress-task' :
                          params.row.status === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? 'completed-task' :
                            params.row.status === statusesConsts.CLOSED_STATUS_ID ? 'closed-task' :
                              params.row.status === statusesConsts.UPCOMING_STATUS_ID ? 'upcoming-task' : ''

                ]}>

                {statusesList?.find((s: any) => s.id === params.row.status)?.name}
                {params.row.status === statusesConsts.OVERDUE_STATUS_ID ?
                  <LocalFireDepartmentOutlinedIcon sx={{ fontSize: 18, marginLeft: 0.5 }} />

                  :
                  <></>
                }

                <KeyboardArrowDownIcon htmlColor={
                  params.row.status === statusesConsts.NEW_STATUS_ID ? '#1280BE' :
                    params.row.status === statusesConsts.OPEN_STATUS_ID ? '#9F7E3A' :
                      params.row.status === statusesConsts.OVERDUE_STATUS_ID ? '#fff' :
                        params.row.status === statusesConsts.IN_PROGRESS_STATUS_ID ? '#00B69B' :
                          params.row.status === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? '#6853A9' :
                            params.row.status === statusesConsts.CLOSED_STATUS_ID ? '#fff' :
                              params.row.status === statusesConsts.UPCOMING_STATUS_ID ? '#FF62E7' : ''

                } />

              </Grid>

            </Grid>
          </HtmlTooltip>

        )
      }
    },
    {
      field: 'type', headerName: intl.formatMessage({ id: 'task.type' }), width: 135,sortable:false,
      renderHeader: (params: any) => {
        return (
          <ClickAwayListener  onClickAway={(event) => {
            const target = event.target as HTMLElement;
            if (
                isOpenType &&
                !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip') 
            ) {
                setIsOpenType(false);
            }
        }}>
          <Grid item container className="table-header" alignItems="center" fontWeight="normal" onClick={() => setIsOpenType((prev) => !prev)}>
            <Message id="task.type" className="Field-input labels font-weight-400" />
            <HtmlTooltip
            open={isOpenType}
            onClose={() => setIsOpenType(false)}
            disableHoverListener
            disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px',boxShadow: '0px 2px 4px 0px #0000001F',padding:'10px',borderRadius:'13px' }}>
                    <Grid item container rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }}>
                      {typesList && typesList.length > 0 &&
                        typesList.map((type: any, index: number) => (
                          <Grid
                            key={type.id}
                            item
                            container
                            alignItems="center"
                            columnGap={2}
                            //className={classes[typeIndex === index || selectedType === type.id ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent='flex-start'
                            className='black'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize:'14px',
                              marginBottom:'18px'
                            }}
                            onMouseOver={(e) => {
                              const target = e.target as HTMLElement;
                              if (clickedType !== type.id) {
                                target.style.cursor = 'pointer';
                                setTypeIndex(index);
                              }
                            }}
                            onMouseOut={() => {
                              setTypeIndex(-1);
                            }}
                            onClick={() => {
                              setClickedType((prevSelectedType: any) => {
                                if (prevSelectedType === type.id) {
                                  return null;
                                } else {
                                  return type.id;
                                }
                              });
                            }}
                          >
                            {/* <Grid
                              className='bordered-box'
                              bgcolor={
                                typeIndex === index || selectedType === type.id ?
                                  '#E3F2FD' : '#E0E0E0'
                              }
                            >
                              <CheckIcon
                                fontSize='small'
                                htmlColor={
                                  typeIndex === index || selectedType === type.id ?
                                    '#2196F3' : '#9E9E9E'
                                }
                              />
                            </Grid> */}
                            
                            <Grid className='bordered-box'
                              style={{ borderColor: clickedType === type.id ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedType === type.id ? '#CCF0EB' : '#fff'}>
                              {
                                clickedType === type.id ?
                                  // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            <Grid item>{type.name}</Grid>
                          </Grid>
                        ))}
                     
                    </Grid>
                    <Grid
                        item
                        container
                        alignItems="center"
                        className="black font-weight-400"
                        columnGap={1}
                        style={{ cursor: clickedType ? 'pointer' : 'unset' }}
                        onClick={() => {
                          setClickedType(0);
                        }}
                      >
                        <RestartAltIcon fontSize="small" />
                        <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                      </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                    <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation();
                      setClickedType(0);
                      setIsOpenType(false);
                    }}>
                    <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation();
                      setSelectedType(clickedType);
                      setIsOpenType(false);
                    }}>
                    <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    
                  </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
             {/* <ArrowDropDownIcon
              style={{
                transform: isOpenType ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease', 
              }}
            /> */}
             <img src={selectedType ? activeFilterIcon:filterIcon} style={{marginLeft:'5px', cursor:'pointer',borderRadius:'2px'}}/>
            </HtmlTooltip>
          </Grid>
          </ClickAwayListener>
        );
      },
      renderCell: (params) => {
        return (
          <Grid item>
            {typesList?.find((s: any) => s.id === params.row.type)?.name}
          </Grid>
        )
      }
    },
    ...(localStorage.getItem('userType') === '1' ? [{
      field: 'client',
      headerName: intl.formatMessage({ id: 'task.client' }),
      width: 230,
      sortable: false,
      renderHeader: (params: any) => {
        return (
          <ClickAwayListener  onClickAway={(event) => {
            const target = event.target as HTMLElement;
            if (
                isOpenClient &&
                !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip') 
            ) {
                setIsOpenClient(false);
            }
        }}>
          <Grid item container className="table-header" alignItems="center" fontWeight="normal" onClick={() => setIsOpenClient((prev) => !prev)}>
            <Message id="task.client" className="Field-input labels font-weight-400" />
            <HtmlTooltip
             open={isOpenClient}
             onClose={() => setIsOpenClient(false)}
             disableHoverListener
             disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px',boxShadow: '0px 2px 4px 0px #0000001F',padding:'10px',borderRadius:'13px'}}>
                  <Grid item container style={{ height: '40px'}}>
                      <TextField
                        value={clientSearch}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom:'10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={(e) => {
                          const searchTerm = e.target.value;
                          setClientSearch(searchTerm);
                          
                          // If search term is empty, show full list
                          if (searchTerm.trim() === '') {
                            setFilteredClientsList(clientsList);
                          } else {
                            // Filter clients based on search term
                            const filtered = clientsList.filter((client: any) => 
                              client.name.toLowerCase().includes(searchTerm.toLowerCase())
                            );
                            
                            setFilteredClientsList(filtered);
                          }
                        }}
                      /></Grid>
                    <Grid item container direction="row"  rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px'  }}>
                   
                      {filteredClientsList  && filteredClientsList .length > 0 &&
                        filteredClientsList .map((client: any, index: number) => (
                          <Grid
                            key={client.id}
                            item
                            container
                            alignItems="center"
                            columnGap={2}
                            //className={classes[clientIndex === index || selectedClient === client.id ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent="flex-start"
                            className='black'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize:'14px',
                              marginBottom:'18px'
                            }}
                            onMouseOver={(e) => {
                              const target = e.target as HTMLElement;
                              if (clickedClient !== client.id) {
                                target.style.cursor = 'pointer';
                                setClientIndex(index);
                              }
                            }}
                            onMouseOut={() => {
                              setClientIndex(-1);
                            }}
                            onClick={() => {
                              setClickedClient((prevSelectedClient: any) => {
                                if (prevSelectedClient === client.id) {
                                  return null;
                                } else {
                                  return client.id;
                                }
                              });
                            }}
                          >
                            {/* <Grid
                              className='bordered-box'
                              bgcolor={
                                clientIndex === index || selectedClient === client.id ?
                                  '#E3F2FD' : '#E0E0E0'
                              }
                            >
                              <CheckIcon
                                fontSize='small'
                                htmlColor={
                                  clientIndex === index || selectedClient === client.id ?
                                    '#2196F3' : '#9E9E9E'
                                }
                              />
                            </Grid> */}
                            
                            <Grid className='bordered-box'
                              style={{ borderColor: clickedClient === client.id ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedClient === client.id ? '#CCF0EB' : '#fff'}>
                              {
                                clickedClient === client.id ?
                                  // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            <Grid item>{client.name}</Grid>
                          </Grid>
                        ))}

                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      className="black font-weight-400"
                      columnGap={1}
                      style={{ cursor: clickedClient ? 'pointer' : 'unset' }}
                      marginLeft={0.8}
                      onClick={() => {
                        setClickedClient("");
                      }}
                    >
                      <RestartAltIcon fontSize="small" />
                      <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                    </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                    <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation();
                      setClickedClient("");
                      setIsOpenClient(false);
                    }}>
                    <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation();
                      setSelectedClient(clickedClient);
                      setIsOpenClient(false);
                    }}>
                    <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    
                  </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
             {/* <ArrowDropDownIcon
              style={{
                transform: isOpenClient ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease', 
              }}
            /> */}
             <img src={selectedClient ? activeFilterIcon:filterIcon} style={{marginLeft:'5px', cursor:'pointer',borderRadius:'2px'}}/>
            </HtmlTooltip>
          </Grid>
          </ClickAwayListener>
        );
      },
      renderCell: (params: { row: { clientId: any } }) => {
        return (
          <Grid item>
            {clientsList?.find((i: any) => i.id === params.row.clientId)?.name}
          </Grid>
        );
      }
    }] : []),
    {
      field: 'assignee',
      headerName: intl.formatMessage({ id: 'task.assignee' }),
      width: 230,
      sortable:false,
      renderHeader: (params) => {
        return (
          <ClickAwayListener  onClickAway={(event) => {
            const target = event.target as HTMLElement;
            if (
                openAssigneesDropdown &&
                !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip') 
            ) {
                setOpenAssigneesDropdown(false);
            }
        }}>
          <Grid item container className="table-header" alignItems="center" fontWeight="normal">
            <Message id="task.assignee" className="Field-input labels font-weight-400" />
            <div
             onClick={() => setOpenAssigneesDropdown((prev) => !prev)}
            >
              <HtmlTooltip
                open={openAssigneesDropdown}
                onClose={() => setOpenAssigneesDropdown(false)}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px',boxShadow: '0px 2px 4px 0px #0000001F',padding:'10px',borderRadius:'13px'}}>
                      <Grid item container direction='row' columnGap={2} marginBottom={1.5} justifyContent='center'>
                        <Grid
                          item
                          className={classes[assigneesType === 'Internal' ? "internal-external-active" : "internal-external-button"]}
                          onClick={() => setAssigneesType('Internal')}
                        >
                          Internal
                        </Grid>
                        <Grid
                          item
                          className={classes[assigneesType === 'External' ? "internal-external-active" : "internal-external-button"]}
                          onClick={() => setAssigneesType('External')}
                        >
                          External
                        </Grid>
                      </Grid>
                      <Grid item container style={{ height: '40px'}}>
                      <TextField
                        value={assigneesType==="External"?externalAssigneeSearch:internalAssigneeSearch}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom:'10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={(e) => {
                          const searchTerm = e.target.value;
                          if (assigneesType === 'External') {
                            setExternalAssigneeSearch(searchTerm);
                            if (searchTerm.trim() === '') {
                              setFilteredExternalAssigneesList(externalAssigneesList);
                            } else {
                              const filtered = externalAssigneesList.filter((externalAssignee: any) => 
                                externalAssignee.name.toLowerCase().includes(searchTerm.toLowerCase())
                              );
                              setFilteredExternalAssigneesList(filtered);
                            }
                          } else {
                            setInternalAssigneeSearch(searchTerm);
                            if (searchTerm.trim() === '') {
                              setFilteredInternalAssigneesList(internalAssigneesList);
                            } else {
                              const filtered = internalAssigneesList.filter((internalAssignee: any) => 
                                internalAssignee.name.toLowerCase().includes(searchTerm.toLowerCase())
                              );
                              setFilteredInternalAssigneesList(filtered);
                            }
                          }
                        }}
                      /></Grid>
                      <Grid item container direction="row"  rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px'  }} className='font-14 black normalFontWeight'>
                        {assigneesType === 'Internal' ? filteredInternalAssigneesList?.map((internalAssignee: any, index: any) => (
                          <Grid
                            item
                            container
                            alignItems='center'
                            key={internalAssignee.id}
                            columnGap={2}
                            // className={classes[assigneeIndex === index || clickedAssignee === internalAssignee.id ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent='flex-start'
                            style={{ cursor: 'pointer',
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize:'14px',
                              marginBottom:'18px' }}
                            onClick={() => {
                              setClickedAssignee(prevAssigneeId => prevAssigneeId === internalAssignee.id ? null : internalAssignee.id);
                             
                            }}
                          >
                           
                            <Grid
                              className='bordered-box'
                              style={{borderColor: clickedAssignee === internalAssignee.id ? '#fff': '#E6E6E8'}}
                              bgcolor={
                                assigneeIndex === index || clickedAssignee === internalAssignee.id ?
                                  '#CCF0EB' : '#fff'
                              }
                            >
                              {
                                clickedAssignee === internalAssignee.id ? 
                              //   <CheckIcon
                              //   fontSize='small'
                              //   htmlColor='#00B69B'
                              //   style={{width:'20px', height:'20px'}}
                              // />
                              <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                              :
                            <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                             
                            </Grid>
                            <Grid item>{internalAssignee.name}</Grid>
                          </Grid>
                        )) :
                          filteredExternalAssigneesList?.map((externalAssignee: any, index: any) => (
                            <Grid
                              item
                              container
                              alignItems='center'
                              justifyContent='flex-start'
                              key={externalAssignee.id}
                              columnGap={2}
                              className={classes[assigneeIndex === index || clickedAssignee === externalAssignee.id ? 'filter-selected' : 'filter-not-selected']}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setClickedAssignee(prevAssigneeId => prevAssigneeId === externalAssignee.id ? null : externalAssignee.id);
                               
                              }}
                            >
                             
                             externalAssignee
                              <Grid item>{externalAssignee.name}</Grid>
                            </Grid>
                          ))}
                       
                      </Grid>
                      <Grid
                          item
                          container
                          alignItems="center"
                          className="black font-weight-400"
                          columnGap={1}
                          style={{ cursor: clickedAssignee ? 'pointer' : 'unset' }}
                          marginLeft={0.8}
                          onClick={() => {
                            setClickedAssignee(undefined);
                          }}
                        >
                          <RestartAltIcon fontSize="small" />
                          <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                        </Grid>
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                    <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation();
                      setClickedAssignee(undefined);
                      setOpenAssigneesDropdown(false);
                    }}>
                    <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation();
                      setSelectedAssignee(clickedAssignee);
                      setOpenAssigneesDropdown(false);
                    }}>
                    <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    
                  </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
               {/* <ArrowDropDownIcon
              style={{
                transform: openAssigneesDropdown ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease', 
              }}
            /> */}
             <img src={selectedAssignee ? activeFilterIcon:filterIcon} style={{marginLeft:'5px',marginTop:'5px', cursor:'pointer',borderRadius:'2px'}}/>
              </HtmlTooltip>
            </div>
          </Grid>
          </ClickAwayListener>
        );
      },

      renderCell: (params) => {
        return (
          <Grid item>
            {internalAssigneesList?.find((i: any) => i.id === params.row.assigneeId)?.name ? internalAssigneesList?.find((i: any) => i.id === params.row.assigneeId)?.name
              :
              userType === 2 && externalAssigneesList?.find((i: any) => i.id === params.row.assigneeId)?.name ? externalAssigneesList?.find((i: any) => i.id === params.row.assigneeId)?.name
                :
                clientsList?.find((i: any) => i.id === params.row.clientId)?.users?.find((i: any) => i.id === params.row.assigneeId)?.name ? clientsList?.find((i: any) => i.id === params.row.clientId)?.users?.find((i: any) => i.id === params.row.assigneeId)?.name
                  : "Unassigned"}

          </Grid>
        )
      }
    },
    {
      field: 'dueDate',
      headerName: intl.formatMessage({ id: 'task.due-date' }),
      width: 140,
      renderHeader: (params) => {
        return (
          <Grid
            container
            className='table-header'
            alignItems='center'
            spacing={1}
            sx={{
              flexWrap: 'nowrap',
              justifyContent: 'flex-start'
            }}
          >
            <Grid item>
              <Message id="task.due-date" className="Field-input labels font-weight-400" />
            </Grid>
            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
              <HtmlTooltip
                open={tooltipOpen}
                onClose={() => setTooltipOpen(false)}
                onOpen={() => setTooltipOpen(true)}
                PopperProps={{
                  placement: "bottom-start",
                  modifiers: [{
                    name: "offset",
                    options: {
                      offset: [-50, -10],
                    },
                  }]
                }}
                title={
                  <div style={{
                    padding: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
                      <DateCalendar
                        views={['day']}
                        value={dueDate ? moment(dueDate, 'YYYY-MM-DD') : null}
                        onChange={handleDateChange}
                        slotProps={{ calendarHeader: { sx: { color: 'black' } } }}
                      />
                    </LocalizationProvider>
                  </div>
                }
              >
                {/* <ArrowDropDownIcon /> */}
                <img src={dueDate ? activeFilterIcon:filterIcon} style={{cursor:'pointer', borderRadius:'2px'}}/>

              </HtmlTooltip>
            </Grid>
          </Grid>
        );
      },
      renderCell: (params) => {
        return (
          <span>{moment(params.row.dueDate).format('DD.MM.YYYY')}</span>
        )
      }
    },
    {
      field: 'recurringtask', headerName: intl.formatMessage({ id: 'task.recurring-task' }), width: 150,
      renderCell: (params) => {
        return (
          <Grid item>
            {params.row.recurringPeriod === 0 ? "No" : "Yes"}
          </Grid>
        )
      }
    },
    {
      field: 'recurringperiod', headerName: intl.formatMessage({ id: 'task.recurring-period' }), width: 215, sortable:false,
      renderHeader: (params) => {
        return (
          <ClickAwayListener  onClickAway={(event) => {
            const target = event.target as HTMLElement;
            if (
                isOpenPeriod &&
                !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip') 
            ) {
                setIsOpenPeriod(false);
            }
        }}>
          <Grid item container className="table-header" alignItems="center" fontWeight="normal" onClick={() => setIsOpenPeriod((prev) => !prev)}>
            <Message id="task.recurring-period" className="Field-input labels font-weight-400" />
            <HtmlTooltip
            open={isOpenPeriod}
            disableHoverListener
            disableFocusListener
            onClose={() => setIsOpenPeriod(false)}
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px',boxShadow: '0px 2px 4px 0px #0000001F',padding:'10px',borderRadius:'13px' }}>
                    <Grid item container  rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }}>
                      {recurringPeriodsList && recurringPeriodsList.length > 0 &&
                        recurringPeriodsList.map((period: any, index: any) => (
                          <Grid
                            key={period.id}
                            item
                            container
                            alignItems="center"
                            columnGap={2}
                            //className={classes[recurringPeriodIndex === index || selectedPeriod === period.id ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent="flex-start"
                            className='black'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize:'14px',
                              marginBottom:'18px'
                            }}
                            onMouseOver={(e) => {
                              const target = e.target as HTMLElement;
                              if (clickedPeriod !== period.id) {
                                target.style.cursor = 'pointer';
                                setRecurringPeriodIndex(index);
                              }
                            }}
                            onMouseOut={() => {
                              setRecurringPeriodIndex(-1);
                            }}
                            onClick={() => {
                              setClickedPeriod((prevSelectedPeriod: any) => {
                                if (prevSelectedPeriod === period.id) {
                                  return null;
                                } else {
                                  return period.id;
                                }
                              });
                            }}
                          >
                            {/* <Grid
                              className='bordered-box'
                              bgcolor={
                                recurringPeriodIndex === index || selectedPeriod === period.id ?
                                  '#E3F2FD' : '#E0E0E0'
                              }
                            >
                              <CheckIcon
                                fontSize='small'
                                htmlColor={
                                  recurringPeriodIndex === index || selectedPeriod === period.id ?
                                    '#2196F3' : '#9E9E9E'
                                }
                              />
                            </Grid> */}
                          
                            <Grid className='bordered-box'
                              style={{ borderColor: clickedPeriod === period.id ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedPeriod === period.id ? '#CCF0EB' : '#fff'}>
                              {
                                clickedPeriod === period.id ?
                                  // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            <Grid item>{period.name}</Grid>
                          </Grid>
                        ))}
                      
                    </Grid>
                    <Grid
                        item
                        container
                        alignItems="center"
                        className="black font-weight-400"
                        columnGap={1}
                        style={{ cursor: clickedPeriod ? 'pointer' : 'unset' }}
                        onClick={() => {
                          setClickedPeriod(undefined);
                        }}
                      >
                        <RestartAltIcon fontSize="small" />
                        <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                      </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                    <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation(); 
                      setClickedPeriod(undefined);
                      setIsOpenPeriod(false);
                    }}>
                    <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation();
                      setSelectedPeriod(clickedPeriod);
                      setIsOpenPeriod(false);
                    }}>
                    <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    
                  </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              {/* <ArrowDropDownIcon
              style={{
                transform: isOpenPeriod ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease', 
              }}
            /> */}
             <img src={selectedPeriod ? activeFilterIcon:filterIcon} style={{marginLeft:'5px', cursor:'pointer',borderRadius:'2px'}}/>
            </HtmlTooltip>
          </Grid>
          </ClickAwayListener>
        )
      },
      renderCell: (params) => {
        return (
          <Grid item>
            {params.row.recurringPeriod === 0 ? "N/A" : recurringPeriodsList?.find((i: any) => i.id === params.row.recurringPeriod)?.name}
          </Grid>
        )
      }
    },
    { field: 'priority', headerName: intl.formatMessage({ id: 'task.priority' }), width: 140 },
    {
      field: 'module', headerName: intl.formatMessage({ id: 'task.module' }), width: 125, sortable: false,
      renderHeader: (params: any) => {
        return (
          <ClickAwayListener  onClickAway={(event) => {
            const target = event.target as HTMLElement;
            if (
                isOpenModule &&
                !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip') 
            ) {
                setIsOpenModule(false);
            }
        }}>
          <Grid item container className="table-header" alignItems="center" fontWeight="normal" onClick={() => setIsOpenModule((prev) => !prev)}>
            <Message id="task.module" className="Field-input labels font-weight-400" />
            <HtmlTooltip
            open={isOpenModule}
            onClose={() => setIsOpenModule(false)}
            disableHoverListener
            disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px',boxShadow: '0px 2px 4px 0px #0000001F',padding:'10px',borderRadius:'13px' }}>
                    <Grid item container rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }}>
                      {moduleList && moduleList.length > 0 &&
                        moduleList.map((m: any, index: number) => (
                          <Grid
                            key={m.id}
                            item
                            container
                            alignItems="center"
                            columnGap={2}
                            //className={classes[moduleIndex === index || selectedModule === m.id ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent="flex-start"
                            className='black'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                               fontSize:'14px',
                              marginBottom:'18px'
                            }}
                            onMouseOver={(e) => {
                              const target = e.target as HTMLElement;
                              if (clickedModule !== m.id) {
                                target.style.cursor = 'pointer';
                                setModuleIndex(index);
                              }
                            }}
                            onMouseOut={() => {
                              setModuleIndex(-1);
                            }}
                            onClick={() => {
                              setClickedModule((prevSelectedModule: any) => {
                                if (prevSelectedModule === m.id) {
                                  return null;
                                } else {
                                  return m.id;
                                }
                              });
                            }}
                          >
                            {/* <Grid
                              className='bordered-box'
                              bgcolor={
                                moduleIndex === index || selectedModule === m.id ?
                                  '#E3F2FD' : '#E0E0E0'
                              }
                            >
                              <CheckIcon
                                fontSize='small'
                                htmlColor={
                                  moduleIndex === index || selectedModule === m.id ?
                                    '#2196F3' : '#9E9E9E'
                                }
                              />
                            </Grid> */}
                            
                            <Grid className='bordered-box'
                              style={{ borderColor: clickedModule === m.id ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedModule === m.id ? '#CCF0EB' : '#fff'}>
                              {
                                clickedModule === m.id ?
                                  // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            <Grid item>{m.name}</Grid>
                          </Grid>
                        ))}
                     
                    </Grid>
                    <Grid
                        item
                        container
                        alignItems="center"
                        className="black font-weight-400"
                        columnGap={1}
                        style={{ cursor: clickedModule ? 'pointer' : 'unset' }}
                        onClick={() => {
                          setClickedModule(0);
                        }}
                      >
                        <RestartAltIcon fontSize="small" />
                        <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                      </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                    <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation(); 
                      setClickedModule(0);
                      setIsOpenModule(false);
                    }}>
                    <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      e.stopPropagation();
                      setSelectedModule(clickedModule);
                      setIsOpenModule(false);
                    }}>
                    <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    
                  </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
             {/* <ArrowDropDownIcon
              style={{
                transform: isOpenModule ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease', 
              }}
            /> */}
             <img src={selectedModule ? activeFilterIcon:filterIcon} style={{marginLeft:'5px', cursor:'pointer', borderRadius:'2px'}}/>
            </HtmlTooltip>
          </Grid>
          </ClickAwayListener>
        );
      },
      renderCell: (params) => {
        return (
          <Grid item>
            {moduleList?.find((s: any) => s.id === params.row.module)?.name}
          </Grid>
        )
      }
    },
    //{ field: 'description', headerName: intl.formatMessage({ id: 'task.description' }), width: 600 },
    // { field: 'startdate', headerName: intl.formatMessage({ id: 'task.start-date' }), width: 115 },
    { field: 'relevantperiod', headerName: intl.formatMessage({ id: 'task.relevant-period' }), width: 110 },

    { field: 'blockedby', headerName: intl.formatMessage({ id: 'task.blocked-by' }), width: 140 },
    { field: 'blocking', headerName: intl.formatMessage({ id: 'task.blocking' }), width: 140 },
    { field: 'note', headerName: intl.formatMessage({ id: 'task.note' }), width: 300 }
  ]




  const updateTaskCall = () => {
    dispatch(setLoading(true));
    updateTask({
      userId: selectedTask.userId,
      //payload
    }).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setActionCallFrom(''));
        }, 4500);
      } else {
        dispatch(setCallStatus("Pass"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setActionCallFrom(''));
          dispatch(setModify(!modify));
        }, 1000);
      }
      dispatch(setLoading(false));
    });
  };

  const deleteTaskCall = () => {
    dispatch(setLoading(true));
    deleteTask(selectedTaskToDelete.id).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''))
        }, 4500)
      }
      else {
        dispatch(setCallStatus('Pass'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''));
          setOpenDeleteDialog(false);
          dispatch(setModify(!modify))
        }, 1000)
      }
      dispatch(setLoading(false));
    })
  }
  const duplicateTaskCall = (taskObject: any, buttonNb: any) => {
    dispatch(setLoading(true));
    duplicateTask(selectedTaskToDuplicate.taskId, taskObject).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 4500);

      }
      else {
        dispatch(setCallStatus("Pass"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          if (buttonNb === 1) {
            setOpen(false);
            dispatch(setModify(!modify));
          }
          if (buttonNb === 2) navigate(`/task-details?id=${x}`)

        }, 1000);
      }
      dispatch(setLoading(false));
    })

  }

  const handlePaginationModelChange = (model: any) => {
    // const adjustedPage = Math.max(1, model.page);
    // // Catch the new page number
    // setPageNb(adjustedPage);
    // setPageSize(model.pageSize);
    // Update the pagination model
    setPaginationModel(model);
  };


  const getRowClassName = (params: any) => {
    return params.row.notifications?.length ? 'bold-row' : ''; // Apply bold class if notifications are present
  };



  return (
    <div style={{ height: '60vh', width: '100%', position: 'relative' }}>
      <style>
        {`
          .bold-row {
            font-weight: bold;
          }
        `}
      </style>
      <Backdrop
        sx={{
          position: 'absolute', // Position it within the container
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          color: '#fff',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          height: '60vh'
        }}
        open={tableSearchLoading}
      >
        <CircularProgress style={{ color: '#c3c2c7' }} />
      </Backdrop>



      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        paginationMode='server'
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange} // Handle pagination changes
        rowCount={dataLength} // Total number of rows for server-side pagination
        pageSizeOptions={[25, 50, 100]}
        getRowClassName={getRowClassName} // Apply the custom row class
        //  checkboxSelection
        disableColumnMenu
        sx={{
          border: 2,
          borderColor: '#FEFEFF',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
          cursor: 'pointer'
        }}
        onRowClick={(params) => {
          navigate(`/task-details?id=${params.row.id}`)
        }}
      />
      <DeleteDialog
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        loading={loading}
        callStatus={callStatus}
        errorMsg={errorMsg}
        object={selectedTaskToDelete}
        confirmAction={deleteTaskCall}
        type='task'
      />

      <Dialog
        open={openCustomReminder}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseCustomReminder}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{ sx: { borderRadius: "8px", width: '340px' } }}

      >
        <Grid item container direction='column' padding={3} rowGap={1} justifyContent='center' textAlign='center'>
          <Grid item container direction='row' justifyContent='space-between'>
            <Grid item> <Message id="task.custom-reminder" className="Field-input labelsLinks font-weight-400" /> </Grid>
            <Grid item style={{ cursor: 'pointer' }} onClick={() => setOpenCustomReminder(false)}><CloseIcon /></Grid>
          </Grid>
          <Grid item className='greyLine' width='100%'></Grid>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
            <DateCalendar
              views={['day']}
              value={reminderDate ? moment(reminderDate, 'YYYY-MM-DD') : null}
              onChange={handleReminderDateChange}
              slotProps={{ calendarHeader: { sx: { color: 'black' } } }}
              sx={{ width: '300px' }}
            />
          </LocalizationProvider>
          <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1.5}>
            {errorMsg ?
              <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
              :
              <></>

            }
            <Grid item xs={3} >
              <button className='greyButton' onClick={() => setOpenCustomReminder(false)}><Message id="button.cancel" className="Field-input labelsLinks labels-extra-bold" /></button>
            </Grid>
            <Grid item xs={5}>
              <button
                className={actionCallFrom === 'post reminder' ? callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton' : 'blueButton'}
                disabled={loading || !reminderDate}
                onClick={() => {
                  if (selectedRowReminders?.length === 0) {
                    postReminderCall(reminderObj, null)
                  }
                  else postReminderCall(reminderObj, selectedRowReminders[0]?.id)

                }
                }
              >
                {loading && actionCallFrom === 'post reminder' ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                  : callStatus === 'Pass' && actionCallFrom === 'post reminder' ? <CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} />
                    : callStatus === 'Fail' && actionCallFrom === 'post reminder' ? "Failed"
                      : <Message id="button.set-reminder" className="Field-input labelsLinks labels-extra-bold"/>}
              </button>
            </Grid>
          </Grid>


        </Grid>
      </Dialog>
    </div>
  )
}

export default TasksTable;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});